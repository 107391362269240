// React
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

// MUI Components
import { ThemeProvider } from "@mui/material/styles"
import Box from "@mui/material/Box"
import MenuItem from "@mui/material/MenuItem"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import Dialog from "@mui/material/Dialog"
import CircularProgress from "@mui/material/CircularProgress"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"

import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers"

// Utils
import newAdapter from "../../utils/newAdapter"
import dayjs, { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"
import jwtDecode from "jwt-decode"
import { useForm } from "react-hook-form"
import Swal from "sweetalert2"

// Styles
import { green } from "@mui/material/colors"
import theme from "../../styles/theme"

// Services
import {
  getAllActiveBank,
  getAllActiveCompany,
  getAllActiveHolidaySchedule,
  getAllActiveIncentiveGroup,
  getAllActivePayGrade,
  getAllActivePayGroup,
  getAllActiveWorkGroup,
  getAllActiveWorkSchedule,
} from "../../services/MasterDataService"
import { getAllSites } from "../../services/SiteService"
import { createECData } from "../../services/ECService"
// import { queryJobs } from '../../services/JobService';
// Services
// import { updateJobStatusTransfer } from "../../services/AppFormService";

// Types
import { Job, TokenPayload, ConfirmJob, Site } from "../../@types"
// import { Job } from '../../@types/Job.type';
// import { Site } from '../../@types/Site.type';
import match from "autosuggest-highlight/match"
import parse from "autosuggest-highlight/parse"
import { Autocomplete } from "@mui/material"

export interface InputDataToECProps {
  app_form_status_id: string[]
  app_form_Data: any
  keepMounted: boolean
  open: boolean
  onClose: (value?: boolean) => void
}

export default function InputDataToDC(props: InputDataToECProps) {
  const { app_form_status_id, app_form_Data, onClose, open, ...other } = props
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [selectedHireDate, setSelectedHireDate] = useState<Dayjs | null>(
    dayjs()
  )
  const [positionId, setPositionId] = useState<string>("")
  const [selectedCompany, setSelectedCompany] = useState<string>("")
  const [selectedIncentiveGroup, setSelectedIncentiveGroup] = useState<any>("N")
  const [selectedPayGrade, setSelectedPayGrade] = useState<string>("JL1")
  const [selectedWorkSchedule, setSelectedWorkSchedule] = useState<string>("")
  const [selectedHolidaySchedule, setSelectedHolidaySchedule] =
    useState<string>("")
  const [selectedWorkGroup, setSelectedWorkGroup] = useState<string>("")
  const [selectedContractStartDate, setSelectedContractStartDate] =
    useState<Dayjs | null>(null)
  const [selectedContractEndDate, setSelectedContractEndDate] =
    useState<Dayjs | null>(null)
  const [selectedBank, setSelectedBank] = useState<string>("")
  const [bankAccountNumber, setBankAccountNumber] = useState<string>("")
  const [selectedPayGroup, setSelectedPayGroup] = useState<string>("")
  // const [selectedJobs, setSelectedJobs] = useState<string>('');
  const [selectedConfirmJobs, setSelectedConfirmJobs] =
    useState<string>("false")

  const [Amount101, setAmount101] = useState<string>("")
  const [Amount102, setAmount102] = useState<string>("")
  const [Amount103_1, setAmount103_1] = useState<string>("")
  const [Amount125, setAmount125] = useState<string>("")
  const [Amount128, setAmount128] = useState<string>("")
  const [Amount133, setAmount133] = useState<string>("")
  const [Amount163, setAmount163] = useState<string>("")

  const [companyList, setCompanyList] = useState<
    { company_code: string; company_name: string }[]
  >([])
  const [payGradeList, setPayGradeList] = useState<
    { external_code: string; label: string }[]
  >([])
  const [incentiveGroupList, setIncentiveGroupList] = useState<
    { external_code: string; label: string }[]
  >([])
  const [workScheduleList, setWorkScheduleList] = useState<
    { external_code: string; label: string }[]
  >([])
  const [holidayScheduleList, setHolidayScheduleList] = useState<
    { external_code: string; label: string }[]
  >([])
  const [workGroupList, setWorkGroupList] = useState<
    { external_code: string; label: string }[]
  >([])
  const [BankList, setBankList] = useState<
    { bank_code: string; bank_name: string }[]
  >([])
  const [payGroupList, setPayGroupList] = useState<
    { pay_group_id: string; label: string }[]
  >([])

  const [submittedLoading, setSubmittedLoading] = useState<boolean>(false)
  const [jobREmail, setjobREmail] = useState<string>(
    app_form_Data?.Job?.hr_email
  )
  const [siteData, setSiteData] = useState<Site[]>([])
  const [selectedSite, setSelectedSite] = useState<string>("") //app_form_Data.Job.Site.site_id
  const emailRegex = /^[a-zA-Z0-9._-]+@betagro\.com$/
  const confirmJobs: ConfirmJob[] = [
    {
      title: "text_confirmJob",
      value: "true",
    },
    {
      title: "text_not_confirmJob",
      value: "false",
    },
  ]

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    const fetchAllActiveCompany = async () => {
      try {
        const response = await getAllActiveCompany()

        if (response.data?.success) {
          setCompanyList(response.data.companies)
        }
      } catch (error) {
        console.log(error)
        navigate("/servererror", { replace: true })
      }
    }

    const fetchAllActiveIncentiveGroup = async () => {
      try {
        const response = await getAllActiveIncentiveGroup()

        if (response.data?.success) {
          setIncentiveGroupList(response.data.incentiveGroups)
        }
      } catch (error) {
        console.log(error)
        navigate("/servererror", { replace: true })
      }
    }

    const fetchAllActivePayGrade = async () => {
      try {
        const response = await getAllActivePayGrade()

        if (response.data?.success) {
          setPayGradeList(response.data.payGrades)
        }
      } catch (error) {
        console.log(error)
        navigate("/servererror", { replace: true })
      }
    }

    const fetchAllActiveWorkSchedule = async () => {
      try {
        const response = await getAllActiveWorkSchedule()

        if (response.data?.success) {
          setWorkScheduleList(response.data.workSchedules)
        }
      } catch (error) {
        console.log(error)
        navigate("/servererror", { replace: true })
      }
    }

    const fetchAllActiveHolidaySchedule = async () => {
      try {
        const response = await getAllActiveHolidaySchedule()

        if (response.data?.success) {
          setHolidayScheduleList(response.data.holidaySchedules)
        }
      } catch (error) {
        console.log(error)
        navigate("/servererror", { replace: true })
      }
    }

    const fetchAllActiveWorkGroup = async () => {
      try {
        const response = await getAllActiveWorkGroup()

        if (response.data?.success) {
          setWorkGroupList(response.data.workGroups)
        }
      } catch (error) {
        console.log(error)
        navigate("/servererror", { replace: true })
      }
    }

    const fetchAllActiveBank = async () => {
      try {
        const response = await getAllActiveBank()

        if (response.data?.success) {
          setBankList(response.data.banks)
        }
      } catch (error) {
        console.log(error)
        navigate("/servererror", { replace: true })
      }
    }

    const fetchAllActivePayGroup = async () => {
      try {
        const response = await getAllActivePayGroup()

        if (response.data?.success) {
          setPayGroupList(response.data.payGroups)
        }
      } catch (error) {
        console.log(error)
        navigate("/servererror", { replace: true })
      }
    }

    // const fetchJobs = async () => {
    //     const queryData = {
    //         is_active: true,
    //         search: ""
    //     }
    //     const response = await queryJobs(queryData);

    //     if (response.data?.success) {
    //         setJobsList(response.data?.jobs);
    //     }
    // };

    const fetchSites = async () => {
      try {
        const response = await getAllSites()

        if (response.data?.success) {
          const sitesData = response.data.sites
          const dataSort = sitesData.sort((a: Site, b: Site) =>
            a.site_id.localeCompare(b.site_id)
          )
          setSiteData(dataSort.filter((site: Site) => site.is_active === true))
        }
      } catch (error) {
        navigate("/servererror", { replace: true })
      }
    }

    if (open) {
      fetchSites()
      fetchAllActiveCompany()
      fetchAllActiveIncentiveGroup()
      fetchAllActivePayGrade()
      fetchAllActiveWorkSchedule()
      fetchAllActiveHolidaySchedule()
      fetchAllActiveWorkGroup()
      fetchAllActiveBank()
      fetchAllActivePayGroup()
      // fetchJobs();
      if (app_form_Data && app_form_Data.Job && app_form_Data.Job.Site) {
        setSelectedSite(app_form_Data.Job.Site.site_id)
      }
    }
  }, [navigate, open])

  const onSubmitted = async () => {
    const checkBank =
      selectedBank.length <= 0
        ? true
        : selectedBank.length > 0 && bankAccountNumber.length === 10
        ? true
        : false
    const checkEmail = emailRegex.test(jobREmail.toLowerCase())
    if (checkBank && checkEmail) {
      try {
        setSubmittedLoading(true)
        const parsedToken: TokenPayload = jwtDecode(localStorage.access_token)
        const ECData = {
          app_form_status_id: app_form_status_id,
          hire_date: dayjs(selectedHireDate).format("DD/MM/YYYY"),
          position_id: positionId,
          company: selectedCompany,
          incentive_group: selectedIncentiveGroup,
          pay_grade: selectedPayGrade,
          work_schedule: selectedWorkSchedule,
          holiday_schedule: selectedHolidaySchedule,
          work_group: selectedWorkGroup,
          contract_start_date: selectedContractStartDate
            ? dayjs(selectedContractStartDate).format("DD/MM/YYYY")
            : null,
          contract_end_date: selectedContractEndDate
            ? dayjs(selectedContractEndDate).format("DD/MM/YYYY")
            : null,
          bank: selectedBank,
          bank_account_number: bankAccountNumber,
          pay_group: selectedPayGroup,
          amount_101: Amount101,
          amount_102: Amount102,
          amount_103_1: Amount103_1,
          amount_125: Amount125,
          amount_128: Amount128,
          amount_133: Amount133,
          amount_163: Amount163,
          created_by: parsedToken.Username,
          job_relationship_email: jobREmail,
          site_id: selectedSite,
        }
        //check Confirm tranf Jobs
        //     if (JSON.parse(selectedConfirmJobs)){
        //         const transferData = await updateJobStatusTransfer({
        //         app_form_status_id: app_form_status_id[0],
        //         jobId : selectedJobs,
        //         transferStatus : JSON.parse(selectedConfirmJobs),
        //         modified_by: parsedToken.Username,

        //         });

        //         if(transferData.status ===200 ){
        //         const response = await createECData(ECData);

        //         if (response.data?.success) {
        //         setSubmittedLoading(false);
        //         onClose(true);

        //         Swal.fire({
        //             icon: 'success',
        //             title: `${t('Success')}`,
        //             text: `${t('Ecsuccess')}`,
        //             confirmButtonText: `${t('ok')}`,
        //         }).then((result) => {
        //             if (result.isConfirmed) {
        //                 window.location.reload();
        //             }
        //         });
        //         } else {
        //         Swal.fire({
        //             icon: 'error',
        //             title: `${t('Error')}`,
        //             text: `${t('ECfailed')}`,
        //             confirmButtonText: `${t('ok')}`,
        //         });
        //         }
        //     }else {
        //         Swal.fire({
        //             icon: 'error',
        //             title: `${t('Error')}`,
        //             text: `${t('ECfailed')}`,
        //             confirmButtonText: `${t('ok')}`,
        //         });
        //     }
        // }else{
        const response = await createECData(ECData)

        if (response.data?.success) {
          setSubmittedLoading(false)
          onClose(true)

          Swal.fire({
            icon: "success",
            title: `${t("Success")}`,
            text: `${t("Ecsuccess")}`,
            confirmButtonText: `${t("ok")}`,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload()
            }
          })
        } else {
          Swal.fire({
            icon: "error",
            title: `${t("Error")}`,
            text: `${t("ECfailed")}`,
            confirmButtonText: `${t("ok")}`,
          })
        }

        // }
        // const ECData = {
        //     app_form_status_id: app_form_status_id,
        //     hire_date: dayjs(selectedHireDate).format('DD/MM/YYYY'),
        //     position_id: positionId,
        //     company: selectedCompany,
        //     incentive_group: selectedIncentiveGroup,
        //     pay_grade: selectedPayGrade,
        //     work_schedule: selectedWorkSchedule,
        //     holiday_schedule: selectedHolidaySchedule,
        //     work_group: selectedWorkGroup,
        //     contract_start_date: selectedContractStartDate ? dayjs(selectedContractStartDate).format('DD/MM/YYYY') : null,
        //     contract_end_date: selectedContractEndDate ? dayjs(selectedContractEndDate).format('DD/MM/YYYY') : null,
        //     bank: selectedBank,
        //     bank_account_number: bankAccountNumber,
        //     pay_group: selectedPayGroup,
        //     amount_101: Amount101,
        //     amount_102: Amount102,
        //     amount_103_1: Amount103_1,
        //     amount_125: Amount125,
        //     amount_128: Amount128,
        //     amount_133: Amount133,
        //     amount_163: Amount163,
        //     created_by: parsedToken.Username
        // }

        // const response = await createECData(ECData);

        // if (response.data?.success) {
        //     setSubmittedLoading(false);
        //     onClose(true);

        //     Swal.fire({
        //         icon: 'success',
        //         title: `${t('Success')}`,
        //         text: `${t('Ecsuccess')}`,
        //         confirmButtonText: `${t('ok')}`,
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             window.location.reload();
        //         }
        //     });
        // } else {
        //     Swal.fire({
        //         icon: 'error',
        //         title: `${t('Error')}`,
        //         text: `${t('ECfailed')}`,
        //         confirmButtonText: `${t('ok')}`,
        //     });
        // }
      } catch (error) {
        console.log(error)
        Swal.fire({
          icon: "error",
          title: `${t("Error")}`,
          text: `${t("Please try again")}`,
          confirmButtonText: `${t("ok")}`,
        })
      }
    }
  }

  // console.log("app_form_Data--->  ", app_form_Data.Job.Site.site_id)

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "100%" } }}
        maxWidth="lg"
        open={open}
        {...other}
      >
        <DialogTitle
          id="scroll-dialog-title"
          sx={{ fontWeight: "bold", fontSize: { xs: 16, sm: 18 } }}
        >
          {t("Input other data for applicant")}
        </DialogTitle>
        <DialogContent
          dividers
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Grid container spacing={3}>
            {/* Job */}
            {/* <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="่jobs" error={errors.company ? true : false}>{t("text_confirm_jobs") + ' *'}</InputLabel>
                                <Select
                                    error={errors.company ? true : false}
                                    labelId="่jobs"
                                    id="่jobs"
                                    label={t("text_job_application") + ' *'}
                                    {...register('่jobs', { required: true })}
                                    value={confirmJobs.length > 0 ? selectedConfirmJobs : "false"}
                                    onChange={(event: SelectChangeEvent) => {
                                        setSelectedConfirmJobs(event.target.value as string);
                                        errors.company = undefined;
                                    }}
                                    MenuProps={{ sx: { maxHeight: 500 } }}
                                >
                                    {
                                        confirmJobs.length > 0 && confirmJobs.map((item: {title: string, value: string }) => {
                                            return <MenuItem key={item.title} value={item.value}>{t(`${item.title}`)}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                            {errors.company?.type === "required" &&
                                <Typography variant="inherit" color="error" align="left" pt={1} ml={1} fontSize={13}>
                                    {t("Company is require")}
                                </Typography>
                            }
                        </Grid> */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  // disabled={selectedConfirmJobs === 'true' ? false : true}
                  fullWidth
                  id="job_relationship_email"
                  label={t("Job Relationship Email") + " *"}
                  placeholder={t("Job Relationship Email")!}
                  {...register("job_relationship_email", {
                    required: selectedConfirmJobs === "true" ? false : true,
                  })}
                  value={jobREmail}
                  onChange={(event) => {
                    setjobREmail(event.target.value)
                    setSelectedConfirmJobs(
                      event.target.value.length > 0 ? "true" : "false"
                    )
                    errors.job_relationship_email = undefined
                  }}
                  // inputProps={{ maxLength: 8 }}
                  error={errors.job_relationship_email ? true : false}
                />
                {selectedConfirmJobs === "true" &&
                  emailRegex.test(jobREmail.toLowerCase()) === false && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t(
                        "Accounts can only be created with Betagro email addresses"
                      )}
                    </Typography>
                  )}
                {/* <Select
                                    error={errors.company ? true : false}
                                    disabled={selectedConfirmJobs === 'true' ? false : true}
                                    labelId="่jobs"
                                    id="่jobs"
                                    label={t("text_job_application") + ' *'}
                                    {...register('่jobs', { required: true })}
                                    value={jobsList.length > 0 ? selectedJobs : ""}
                                    onChange={(event: SelectChangeEvent) => {
                                        setSelectedJobs(event.target.value as string);
                                        errors.company = undefined;
                                    }}
                                    MenuProps={{ sx: { maxHeight: 500 } }}
                                >
                                    {
                                        jobsList.length > 0 && jobsList.map((item: {id: string, job_name: string }) => {
                                            return <MenuItem key={item.id} value={item.id}>{`${item.job_name}`}</MenuItem>
                                        })
                                    }
                                </Select> */}
              </FormControl>
              {/* {errors.company?.type === "required" &&
                                <Typography variant="inherit" color="error" align="left" pt={1} ml={1} fontSize={13}>
                                    {t("Company is require")}
                                </Typography>
                            } */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                {/* <InputLabel id="site" error={errors.site ? true : false}> */}
                <InputLabel id="site">
                  {t("Site") + " *"}
                </InputLabel>
                <Select
                  labelId="site"
                  id="site"
                  label={t("site") + " *"}
                  {...register("site")}
                  value={selectedSite}
                  onChange={(event: SelectChangeEvent) => {
                    setSelectedSite(event.target.value as string)
                  }}
                  MenuProps={{ sx: { maxHeight: 500 } }}
                >
                  {siteData.length > 0 &&
                    siteData.map(
                      (item: { site_id: string; site_name: string }) => {
                        return (
                          <MenuItem
                            key={item.site_id}
                            value={item.site_id}
                          >{`${item.site_id} - ${item.site_name}`}</MenuItem>
                        )
                      }
                    )}
                </Select>
              </FormControl>

              {/* <Select
                  error={errors.site ? true : false}
                  required
                  labelId="site"
                  id="site"
                  label={t("site") + " *"}
                  {...register("site", { required: true })}
                  value={selectedSite}
                  onChange={(event: SelectChangeEvent) => {
                    setSelectedSite(event.target.value as string)
                    errors.site = undefined
                  }}
                  MenuProps={{ sx: { maxHeight: 500 } }}
                >
                  {siteData.length > 0 &&
                    siteData.map(
                      (item: { site_id: string; site_name: string }) => {
                        return (
                          <MenuItem
                            key={item.site_id}
                            value={item.site_id}
                          >{`${item.site_id} - ${item.site_name}`}</MenuItem>
                        )
                      }
                    )}
                </Select>
              </FormControl>
              {errors.site?.type === "required" && (
                <Typography
                  variant="inherit"
                  color="error"
                  align="left"
                  pt={1}
                  ml={1}
                  fontSize={13}
                >
                  {t("Site is require")}
                </Typography>
              )} */}
            </Grid>
            <Grid item xs={12} sm={12}>
              <LocalizationProvider dateAdapter={newAdapter} adapterLocale="th">
                <MobileDatePicker
                  sx={{ width: "100%" }}
                  label={t("Hire Date") + " *"}
                  format="DD/MM/YYYY"
                  {...register("hire_date", { required: true })}
                  closeOnSelect
                  localeText={{
                    okButtonLabel: `${t("ok")}`,
                    cancelButtonLabel: `${t("Cancel")}`,
                  }}
                  value={selectedHireDate}
                  dayOfWeekFormatter={(_day, weekday) =>
                    `${weekday.format("dd")}.`
                  }
                  onChange={(newValue) => {
                    try {
                      if (newValue !== null) {
                        setSelectedHireDate(dayjs(newValue))
                      }
                    } catch (error) {}
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="position_id"
                label={t("No. position") + " *"}
                placeholder={t("No. position")!}
                {...register("position_id", {
                  required: true,
                  maxLength: 8,
                  minLength: 8,
                })}
                value={positionId}
                onChange={(event) => {
                  setPositionId(event.target.value)
                  errors.position_id = undefined
                }}
                inputProps={{ maxLength: 8 }}
                error={errors.position_id ? true : false}
              />
              {errors.position_id?.type === "required" && (
                <Typography
                  variant="inherit"
                  color="error"
                  align="left"
                  pt={1}
                  ml={1}
                  fontSize={13}
                >
                  {t("No. position is required")}
                </Typography>
              )}
              {errors.position_id?.type === "maxLength" && (
                <Typography
                  variant="inherit"
                  color="error"
                  align="left"
                  pt={1}
                  ml={1}
                  fontSize={13}
                >
                  {t("No. position must be at least 8 characters")}
                </Typography>
              )}
              {errors.position_id?.type === "minLength" && (
                <Typography
                  variant="inherit"
                  color="error"
                  align="left"
                  pt={1}
                  ml={1}
                  fontSize={13}
                >
                  {t("No. position must be at least 8 characters")}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                {/* <InputLabel id="company" error={errors.company ? true : false}>
                  {t("Company") + " *"}
                </InputLabel>
                <Select
                  error={errors.company ? true : false}
                  required
                  labelId="company"
                  id="company"
                  label={t("Company") + " *"}
                  {...register("company", { required: true })}
                  value={companyList.length > 0 ? selectedCompany : ""}
                  onChange={(event: SelectChangeEvent) => {
                    setSelectedCompany(event.target.value as string)
                    errors.company = undefined
                  }}
                  MenuProps={{ sx: { maxHeight: 500 } }}
                >
                  {companyList.length > 0 &&
                    companyList.map(
                      (item: {
                        company_code: string
                        company_name: string
                      }) => {
                        return (
                          <MenuItem
                            key={item.company_code}
                            value={item.company_code}
                          >{`${item.company_code} - ${item.company_name}`}</MenuItem>
                        )
                      }
                    )}
                </Select> */}
                <Autocomplete
                  onChange={(
                    event,
                    newValue: {
                      company_code: string
                      company_name: string
                    } | null
                  ) => {
                    setSelectedCompany(newValue?.company_code || "")
                  }}
                  options={companyList}
                  getOptionLabel={(option) =>
                    option.company_code + " - " + option.company_name
                  }
                  value={
                    companyList.filter(
                      (item) => item.company_code === selectedCompany
                    )[0]
                  }
                  renderOption={(props, option, { inputValue, selected }) => {
                    const matches = match(
                      option.company_code + " - " + option.company_name,
                      inputValue,
                      {
                        insideWords: true,
                      }
                    )
                    const parts = parse(
                      option.company_code + " - " + option.company_name,
                      matches
                    )

                    return (
                      <li {...props}>
                        <div>
                          {parts.map((part: any, index: any) => (
                            <span
                              key={index}
                              style={{
                                fontWeight: part.highlight ? 700 : 400,
                              }}
                            >
                              {part.text}
                            </span>
                          ))}
                        </div>
                      </li>
                    )
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t("Company") + " *"}
                      label={t("Company") + " *"}
                    />
                  )}
                />
              </FormControl>
              {errors.company?.type === "required" && (
                <Typography
                  variant="inherit"
                  color="error"
                  align="left"
                  pt={1}
                  ml={1}
                  fontSize={13}
                >
                  {t("Company is require")}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel
                  id="incentiveGroup"
                  error={errors.incentive_group ? true : false}
                >
                  {t("Incentive Group") + " *"}
                </InputLabel>
                <Select
                  error={errors.incentive_group ? true : false}
                  required
                  labelId="incentiveGroup"
                  id="incentiveGroup"
                  label={t("Incentive Group") + " *"}
                  {...register("incentive_group", { required: true })}
                  value={selectedIncentiveGroup}
                  onChange={(event: SelectChangeEvent) => {
                    setSelectedIncentiveGroup(event.target.value as string)
                    errors.incentive_group = undefined
                  }}
                >
                  {incentiveGroupList.length > 0 &&
                    incentiveGroupList.map(
                      (item: { external_code: string; label: string }) => {
                        return (
                          <MenuItem
                            key={item.external_code}
                            value={item.external_code}
                            selected={true}
                          >
                            {item.label}
                          </MenuItem>
                        )
                      }
                    )}
                </Select>
              </FormControl>
              {errors.incentive_group?.type === "required" && (
                <Typography
                  variant="inherit"
                  color="error"
                  align="left"
                  pt={1}
                  ml={1}
                  fontSize={13}
                >
                  {t("Incentive Group is required")}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                {/* <InputLabel
                  id="workSchedule"
                  error={errors.work_schedule ? true : false}
                >
                  {t("Work Schedule") + " *"}
                </InputLabel> */}
                {/* <Select
                  error={errors.work_schedule ? true : false}
                  required
                  labelId="workSchedule"
                  id="workSchedule"
                  label={t("Work Schedule") + " *"}
                  {...register("work_schedule", { required: true })}
                  value={selectedWorkSchedule}
                  onChange={(event: SelectChangeEvent) => {
                    setSelectedWorkSchedule(event.target.value as string)
                    errors.work_schedule = undefined
                  }}
                >
                  {workScheduleList.length > 0 &&
                    workScheduleList.map(
                      (item: { external_code: string; label: string }) => {
                        return (
                          <MenuItem
                            key={item.external_code}
                            value={item.external_code}
                          >
                            {item.label}
                          </MenuItem>
                        )
                      }
                    )}
                </Select> */}
                <Autocomplete
                  onChange={(
                    event,
                    newValue: {
                      external_code: string
                      label: string
                    } | null
                  ) => {
                    setSelectedWorkSchedule(newValue?.external_code || "")
                  }}
                  options={workScheduleList}
                  getOptionLabel={(option) => option.label}
                  value={
                    workScheduleList.filter(
                      (item) => item.external_code === selectedWorkSchedule
                    )[0]
                  }
                  renderOption={(props, option, { inputValue, selected }) => {
                    const matches = match(option.label, inputValue, {
                      insideWords: true,
                    })
                    const parts = parse(option.label, matches)

                    return (
                      <li {...props}>
                        <div>
                          {parts.map((part: any, index: any) => (
                            <span
                              key={index}
                              style={{
                                fontWeight: part.highlight ? 700 : 400,
                              }}
                            >
                              {part.text}
                            </span>
                          ))}
                        </div>
                      </li>
                    )
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t("Work Schedule") + " *"}
                      label={t("Work Schedule") + " *"}
                    />
                  )}
                />
              </FormControl>
              {errors.work_schedule?.type === "required" && (
                <Typography
                  variant="inherit"
                  color="error"
                  align="left"
                  pt={1}
                  ml={1}
                  fontSize={13}
                >
                  {t("Work Schedule is required")}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                {/* <InputLabel
                  id="holidaySchedule"
                  error={errors.holiday_schedule ? true : false}
                >
                  {t("Holiday Schedule") + " *"}
                </InputLabel>
                <Select
                  error={errors.holiday_schedule ? true : false}
                  required
                  labelId="holidaySchedule"
                  id="holidaySchedule"
                  label={t("Holiday Schedule") + " *"}
                  {...register("holiday_schedule", { required: true })}
                  value={selectedHolidaySchedule}
                  onChange={(event: SelectChangeEvent) => {
                    setSelectedHolidaySchedule(event.target.value as string)
                    errors.holiday_schedule = undefined
                  }}
                  MenuProps={{ sx: { maxHeight: 500 } }}
                >
                  {holidayScheduleList.length > 0 &&
                    holidayScheduleList.map(
                      (item: { external_code: string; label: string }) => {
                        return (
                          <MenuItem
                            key={item.external_code}
                            value={item.external_code}
                          >
                            {item.label}
                          </MenuItem>
                        )
                      }
                    )}
                </Select> */}
                <Autocomplete
                  onChange={(
                    event,
                    newValue: {
                      external_code: string
                      label: string
                    } | null
                  ) => {
                    setSelectedHolidaySchedule(newValue?.external_code || "")
                  }}
                  options={holidayScheduleList}
                  getOptionLabel={(option) => option.label}
                  value={
                    holidayScheduleList.filter(
                      (item) => item.external_code === selectedHolidaySchedule
                    )[0]
                  }
                  renderOption={(props, option, { inputValue, selected }) => {
                    const matches = match(option.label, inputValue, {
                      insideWords: true,
                    })
                    const parts = parse(option.label, matches)

                    return (
                      <li {...props}>
                        <div>
                          {parts.map((part: any, index: any) => (
                            <span
                              key={index}
                              style={{
                                fontWeight: part.highlight ? 700 : 400,
                              }}
                            >
                              {part.text}
                            </span>
                          ))}
                        </div>
                      </li>
                    )
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t("Holiday Schedule") + " *"}
                      label={t("Holiday Schedule") + " *"}
                    />
                  )}
                />
              </FormControl>
              {errors.holiday_schedule?.type === "required" && (
                <Typography
                  variant="inherit"
                  color="error"
                  align="left"
                  pt={1}
                  ml={1}
                  fontSize={13}
                >
                  {t("Holiday Schedule is required")}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel
                  id="workGroup"
                  error={errors.work_group ? true : false}
                >
                  {t("Work Group") + " *"}
                </InputLabel>
                <Select
                  error={errors.work_group ? true : false}
                  required
                  labelId="workGroup"
                  id="workGroup"
                  label={t("Work Group") + " *"}
                  {...register("work_group", { required: true })}
                  value={selectedWorkGroup}
                  onChange={(event: SelectChangeEvent) => {
                    setSelectedWorkGroup(event.target.value as string)
                    errors.work_group = undefined
                  }}
                  MenuProps={{ sx: { maxHeight: 500 } }}
                >
                  {workGroupList.length > 0 &&
                    workGroupList.map(
                      (item: { external_code: string; label: string }) => {
                        return (
                          <MenuItem
                            key={item.external_code}
                            value={item.external_code}
                          >
                            {item.label}
                          </MenuItem>
                        )
                      }
                    )}
                </Select>
              </FormControl>
              {errors.work_group?.type === "required" && (
                <Typography
                  variant="inherit"
                  color="error"
                  align="left"
                  pt={1}
                  ml={1}
                  fontSize={13}
                >
                  {t("Work Group is required")}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={newAdapter} adapterLocale="th">
                <MobileDatePicker
                  sx={{ width: "100%" }}
                  label={t("Contract Start Date")}
                  format="DD/MM/YYYY"
                  closeOnSelect
                  {...register("contract_start_date")}
                  localeText={{
                    okButtonLabel: `${t("ok")}`,
                    cancelButtonLabel: `${t("Cancel")}`,
                    clearButtonLabel: `${t("Clear")}`,
                  }}
                  value={selectedContractStartDate}
                  dayOfWeekFormatter={(_day, weekday) =>
                    `${weekday.format("dd")}.`
                  }
                  onChange={(newValue) => {
                    try {
                      if (newValue !== null) {
                        setSelectedContractStartDate(dayjs(newValue))
                        // setSelectedHireDate(dayjs(newValue));
                      } else {
                        setSelectedContractStartDate(null)
                      }
                    } catch (error) {}
                  }}
                  slotProps={{
                    actionBar: {
                      actions: ["clear", "cancel", "accept"],
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={newAdapter} adapterLocale="th">
                <MobileDatePicker
                  sx={{ width: "100%" }}
                  label={t("Contract End Date")}
                  format="DD/MM/YYYY"
                  {...register("contract_end_date")}
                  closeOnSelect
                  localeText={{
                    okButtonLabel: `${t("ok")}`,
                    cancelButtonLabel: `${t("Cancel")}`,
                    clearButtonLabel: `${t("Clear")}`,
                  }}
                  value={selectedContractEndDate}
                  dayOfWeekFormatter={(_day, weekday) =>
                    `${weekday.format("dd")}.`
                  }
                  onChange={(newValue) => {
                    try {
                      if (newValue !== null) {
                        setSelectedContractEndDate(dayjs(newValue))
                      } else {
                        setSelectedContractEndDate(null)
                      }
                    } catch (error) {}
                  }}
                  slotProps={{
                    actionBar: {
                      actions: ["clear", "cancel", "accept"],
                    },
                  }}
                  minDate={
                    selectedContractStartDate
                      ? selectedContractStartDate
                      : dayjs()
                  }
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="Bank" error={errors.bank ? true : false}>
                  {t("ธนาคาร") + " *"}
                </InputLabel>
                <Select
                  error={errors.bank ? true : false}
                  labelId="Bank"
                  id="Bank"
                  label={t("Bank") + " *"}
                  // {...register('bank', { required: true })}
                  {...register("bank")}
                  value={selectedBank}
                  onChange={(event: SelectChangeEvent) => {
                    setSelectedBank(event.target.value as string)
                    setBankAccountNumber(
                      event.target.value.length <= 0 ? "" : bankAccountNumber
                    )
                    // setSelectedBank(event.target.value as string);
                    // errors.bank = undefined;
                  }}
                  MenuProps={{ sx: { maxHeight: 500 } }}
                  // required
                >
                  <MenuItem value="">{t("Not specified")}</MenuItem>
                  {BankList.length > 0 &&
                    BankList.map(
                      (item: { bank_code: string; bank_name: string }) => {
                        return (
                          <MenuItem key={item.bank_code} value={item.bank_code}>
                            {item.bank_name}
                          </MenuItem>
                        )
                      }
                    )}
                  {errors.bank?.type === "required" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("text_err_required_bank")}
                    </Typography>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                id="bank_account_number"
                label={t("Bank account number")}
                placeholder={t("Bank account number")!}
                // {...register('bank_account_number', { required: true, maxLength: 10, minLength: 10})}
                value={bankAccountNumber}
                InputProps={{
                  type: "number",
                  sx: {
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  },
                }}
                // inputProps={{ maxLength: 10, minLength: 10 }}
                disabled={selectedBank.length <= 0 ? true : false}
                {...register("bank_account_number", {
                  required: selectedBank.length > 0 ? true : false,
                })}
                onChange={(event) => {
                  setBankAccountNumber(event.target.value)
                  // errors.bank_account_number = undefined;
                }}
                // error={errors.bank_account_number ? true : false}
              />
              {selectedBank.length > 0 &&
                (bankAccountNumber.length > 10 ||
                  bankAccountNumber.length < 10) && (
                  <Typography
                    variant="inherit"
                    color="error"
                    align="left"
                    pt={1}
                    ml={1}
                    fontSize={13}
                  >
                    {t("No. position must be at least 10 characters")}
                  </Typography>
                )}
              {/* {errors.bank_account_number?.type === "required" &&
                                        <Typography variant="inherit" color="error" align="left" pt={1} ml={1} fontSize={13}>
                                            {t('text_err_required_bank_number')}
                                        </Typography>
                            }
                            {
                                (bankAccountNumber.length > 10 || bankAccountNumber.length < 10)  &&
                                <Typography variant="inherit" color="error" align="left" pt={1} ml={1} fontSize={13}>
                                    {t("No. position must be at least 10 characters")}
                                </Typography>
                            } */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                {/* <InputLabel
                  id="payGroup"
                  error={errors.pay_group ? true : false}
                >
                  {t("Pay Group") + " *"}
                </InputLabel>
                <Select
                  error={errors.pay_group ? true : false}
                  required
                  labelId="payGroup"
                  id="payGroup"
                  label={t("Pay Group") + " *"}
                  {...register("pay_group", { required: true })}
                  value={selectedPayGroup}
                  onChange={(event: SelectChangeEvent) => {
                    setSelectedPayGroup(event.target.value as string)
                    errors.pay_group = undefined
                  }}
                  MenuProps={{ sx: { maxHeight: 500 } }}
                >
                  {payGroupList.length > 0 &&
                    payGroupList.map(
                      (item: { pay_group_id: string; label: string }) => {
                        return (
                          <MenuItem
                            key={item.pay_group_id}
                            value={item.pay_group_id}
                          >
                            {item.label}
                          </MenuItem>
                        )
                      }
                    )}
                </Select> */}
                <Autocomplete
                  onChange={(
                    event,
                    newValue: {
                      pay_group_id: string
                      label: string
                    } | null
                  ) => {
                    setSelectedPayGroup(newValue?.pay_group_id || "")
                  }}
                  options={payGroupList}
                  getOptionLabel={(option) => option.label}
                  value={
                    payGroupList.filter(
                      (item) => item.pay_group_id === selectedPayGroup
                    )[0]
                  }
                  renderOption={(props, option, { inputValue, selected }) => {
                    const matches = match(option.label, inputValue, {
                      insideWords: true,
                    })
                    const parts = parse(option.label, matches)

                    return (
                      <li {...props}>
                        <div>
                          {parts.map((part: any, index: any) => (
                            <span
                              key={index}
                              style={{
                                fontWeight: part.highlight ? 700 : 400,
                              }}
                            >
                              {part.text}
                            </span>
                          ))}
                        </div>
                      </li>
                    )
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t("Pay Group") + " *"}
                      label={t("Pay Group") + " *"}
                    />
                  )}
                />
              </FormControl>
              {errors.pay_group?.type === "required" && (
                <Typography
                  variant="inherit"
                  color="error"
                  align="left"
                  pt={1}
                  ml={1}
                  fontSize={13}
                >
                  {t("Pay Group is required")}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="amount_101"
                label={t("101_Amount")}
                placeholder={t("101_Amount")!}
                value={Amount101}
                InputProps={{
                  type: "number",
                  sx: {
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  },
                }}
                onChange={(event) => {
                  setAmount101(event.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="amount_102"
                label={t("102_Amount")}
                placeholder={t("102_Amount")!}
                value={Amount102}
                InputProps={{
                  type: "number",
                  sx: {
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  },
                }}
                onChange={(event) => {
                  setAmount102(event.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="amount_103"
                label={t("103_Amount")}
                placeholder={t("103_Amount")!}
                value={Amount103_1}
                InputProps={{
                  type: "number",
                  sx: {
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  },
                }}
                onChange={(event) => {
                  setAmount103_1(event.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="amount_125"
                label={t("125_Amount")}
                placeholder={t("125_Amount")!}
                value={Amount125}
                InputProps={{
                  type: "number",
                  sx: {
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  },
                }}
                onChange={(event) => {
                  setAmount125(event.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="amount_128"
                label={t("128_Amount")}
                placeholder={t("128_Amount")!}
                value={Amount128}
                InputProps={{
                  type: "number",
                  sx: {
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  },
                }}
                onChange={(event) => {
                  setAmount128(event.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="amount_133"
                label={t("133_Amount")}
                placeholder={t("133_Amount")!}
                value={Amount133}
                InputProps={{
                  type: "number",
                  sx: {
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  },
                }}
                onChange={(event) => {
                  setAmount133(event.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="amount_163"
                label={t("163_Amount")}
                placeholder={t("163_Amount")!}
                value={Amount163}
                InputProps={{
                  type: "number",
                  sx: {
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  },
                }}
                onChange={(event) => {
                  setAmount163(event.target.value)
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            px: 3,
            py: 2,
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                onClose()
              }}
              sx={{ px: 3 }}
            >
              {t("Cancel")}
            </Button>
            <Button
              color="error"
              onClick={() => {
                setPositionId("")
                setSelectedCompany("")
                setSelectedIncentiveGroup("N")
                setSelectedPayGrade("JL1")
                setSelectedWorkSchedule("")
                setSelectedHolidaySchedule("")
                setSelectedWorkGroup("")
                setSelectedContractStartDate(null)
                setSelectedContractEndDate(null)
                setSelectedBank("")
                setBankAccountNumber("")
                setSelectedPayGroup("")
                setAmount101("")
                setAmount102("")
                setAmount103_1("")
                setAmount125("")
                setAmount128("")
                setAmount133("")
                setAmount163("")
              }}
              sx={{ px: 3 }}
            >
              {t("Clear")}
            </Button>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmitted)}
            sx={{ px: 3 }}
            disabled={submittedLoading}
          >
            {t("Confirm")}
            {submittedLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: "absolute",
                  zIndex: 999,
                }}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}
