// React
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

// Hooks and Redux
// MUI Components
import { ThemeProvider } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import InputLabel from "@mui/material/InputLabel"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import CircularProgress from "@mui/material/CircularProgress"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Dialog from "@mui/material/Dialog"
import { toast } from "react-toastify"

import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers"

// Utils
import newAdapter from "../../../utils/newAdapter"
import dayjs, { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"
import Swal from "sweetalert2"
import jwtDecode from "jwt-decode"
import { useForm } from "react-hook-form"

// Styles
import { green } from "@mui/material/colors"
import theme from "../../../styles/theme"

// Types
import { TokenPayload, Site } from "../../../@types"

// Services
import {
  getAllActiveBank,
  getAllActiveCompany,
  getAllActiveCountry,
  getAllActiveHolidaySchedule,
  getAllActiveIncentiveGroup,
  getAllActiveMaritalStatus,
  getAllActivePayGrade,
  getAllActivePayGroup,
  getAllActiveReligion,
  getAllActiveWorkGroup,
  getAllActiveWorkSchedule,
  getAllActivePrefix,
} from "../../../services/MasterDataService"
import { getAllSites } from "../../../services/SiteService"

import { editECData, getECDataById } from "../../../services/ECService"
import THAProvinceJSON from "../../../services/REGION_THA/Province.json"
import LAOProvinceJSON from "../../../services/REGION_LAO/Province.json"
import KHMProvinceJSON from "../../../services/REGION_KHM/Province.json"
import { Autocomplete, AutocompleteRenderInputParams } from "@mui/material"
import match from "autosuggest-highlight/match"
import parse from "autosuggest-highlight/parse"

export interface InputDataToECProps {
  ec_data_id: string
  keepMounted: boolean
  open: boolean
  onClose: (value?: boolean) => void
}

export default function EditECData(props: InputDataToECProps) {
  const { ec_data_id, onClose, open, ...other } = props
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  const [nationalID, setNationalID] = useState<string>("")
  const [countryOfNationalID, setCountryOfNationalID] = useState<string>("")
  const [nationalIDCardType, setNationalIDCardType] = useState<string>("")
  const [countryOfBirth, setCountryOfBirth] = useState<string>("")
  const [dateOfBirth, setDateOfBirth] = useState<Dayjs | null>(null)
  const [prefix, setPrefix] = useState<string>("")
  const [firstNameTH, setFirstNameTH] = useState<string>("")
  const [lastNameTH, setLastNameTH] = useState<string>("")
  const [firstNameEN, setFirstNameEN] = useState<string>("")
  const [lastNameEN, setLastNameEN] = useState<string>("")
  const [gender, setGender] = useState<string>("")
  const [nationality, setNationality] = useState<string>("")
  const [maritalStatus, setMaritalStatus] = useState<string>("")
  const [religion, setReligion] = useState<string>("")
  const [phoneNumber, setPhoneNumber] = useState<string>("")
  const [legalCountry, setLegalCountry] = useState<string>("")
  const [legalHouseNumber, setLegalHouseNumber] = useState<string>("")
  const [legalMoo, setLegalMoo] = useState<string>("")
  const [legalStreet, setLegalStreet] = useState<string>("")
  const [legalLane, setLegalLane] = useState<string>("")
  const [legalSubDistrict, setLegalSubDistrict] = useState<string>("")
  const [legalDistrict, setLegalDistrict] = useState<string>("")
  const [legalProvince, setLegalProvince] = useState<string>("")
  const [legalPostalCode, setLegalPostalCode] = useState<string>("")
  const [sameAsLegal, setSameAsLegal] = useState<boolean>(false)
  const [mailingCountry, setMailingCountry] = useState<string>("")
  const [mailingHouseNumber, setMailingHouseNumber] = useState<string>("")
  const [mailingMoo, setMailingMoo] = useState<string>("")
  const [mailingStreet, setMailingStreet] = useState<string>("")
  const [mailingLane, setMailingLane] = useState<string>("")
  const [mailingSubDistrict, setMailingSubDistrict] = useState<string>("")
  const [mailingDistrict, setMailingDistrict] = useState<string>("")
  const [mailingProvince, setMailingProvince] = useState<string>("")
  const [mailingPostalCode, setMailingPostalCode] = useState<string>("")

  const [selectedHireDate, setSelectedHireDate] = useState<Dayjs | null>(
    dayjs()
  )
  const [positionId, setPositionId] = useState<string>("")
  const [selectedCompany, setSelectedCompany] = useState<string>("")
  const [selectedIncentiveGroup, setSelectedIncentiveGroup] =
    useState<string>("")
  const [selectedPayGrade, setSelectedPayGrade] = useState<string>("")
  const [selectedWorkSchedule, setSelectedWorkSchedule] = useState<string>("")
  const [selectedHolidaySchedule, setSelectedHolidaySchedule] =
    useState<string>("")
  const [selectedWorkGroup, setSelectedWorkGroup] = useState<string>("")
  const [selectedContractStartDate, setSelectedContractStartDate] =
    useState<Dayjs | null>(null)
  const [selectedContractEndDate, setSelectedContractEndDate] =
    useState<Dayjs | null>(null)
  const [selectedBank, setSelectedBank] = useState<string>("")
  const [bankAccountNumber, setBankAccountNumber] = useState<string>("")
  const [selectedPayGroup, setSelectedPayGroup] = useState<string>("")
  const [Amount101, setAmount101] = useState<string>("")
  const [Amount102, setAmount102] = useState<string>("")
  const [Amount103_1, setAmount103_1] = useState<string>("")
  const [Amount125, setAmount125] = useState<string>("")
  const [Amount128, setAmount128] = useState<string>("")
  const [Amount133, setAmount133] = useState<string>("")
  const [Amount163, setAmount163] = useState<string>("")

  const [countryList, setCountryList] = useState<
    { country_code: string; country_name: string }[]
  >([])
  const [religionList, setReligionList] = useState<
    { external_code: string; label: string }[]
  >([])
  const [maritalStatusList, setMaritalStatusList] = useState<
    { external_code: string; label: string }[]
  >([])

  const [companyList, setCompanyList] = useState<
    { company_code: string; company_name: string }[]
  >([])
  const [payGradeList, setPayGradeList] = useState<
    { external_code: string; label: string }[]
  >([])
  const [incentiveGroupList, setIncentiveGroupList] = useState<
    { external_code: string; label: string }[]
  >([])
  const [workScheduleList, setWorkScheduleList] = useState<
    { external_code: string; label: string }[]
  >([])
  const [holidayScheduleList, setHolidayScheduleList] = useState<
    { external_code: string; label: string }[]
  >([])
  const [workGroupList, setWorkGroupList] = useState<
    { external_code: string; label: string }[]
  >([])
  const [bankList, setBankList] = useState<
    { bank_code: string; bank_name: string }[]
  >([])
  const [payGroupList, setPayGroupList] = useState<
    { pay_group_id: string; label: string }[]
  >([])

  const [loadingCountryList, setLoadingCountryList] = useState<boolean>(true)
  const [loadingReligionList, setLoadingReligionList] = useState<boolean>(true)
  const [loadingMaritalStatusList, setLoadingMaritalStatusList] =
    useState<boolean>(true)
  const [loadingCompanyList, setLoadingCompanyList] = useState<boolean>(true)
  const [loadingPayGradeList, setLoadingPayGradeList] = useState<boolean>(true)
  const [loadingIncentiveGroupList, setLoadingIncentiveGroupList] =
    useState<boolean>(true)
  const [loadingWorkScheduleList, setLoadingWorkScheduleList] =
    useState<boolean>(true)
  const [loadingHolidayScheduleList, setLoadingHolidayScheduleList] =
    useState<boolean>(true)
  const [loadingWorkGroupList, setLoadingWorkGroupList] =
    useState<boolean>(true)
  const [loadingBankList, setLoadingBankList] = useState<boolean>(true)
  const [loadingPayGroupList, setLoadingPayGroupList] = useState<boolean>(true)

  const [loadingECData, setLoadingECData] = useState<boolean>(true)
  const [submittedLoading, setSubmittedLoading] = useState<boolean>(false)
  const [prefixList, setPrefixList] = useState<any>([])
  const allSuccessLoading =
    !loadingCountryList &&
    !loadingReligionList &&
    !loadingMaritalStatusList &&
    !loadingCompanyList &&
    !loadingPayGradeList &&
    !loadingIncentiveGroupList &&
    !loadingWorkScheduleList &&
    !loadingHolidayScheduleList &&
    !loadingWorkGroupList &&
    !loadingBankList &&
    !loadingPayGroupList &&
    !loadingECData

  const [statusValidate, setStatusValidate] = useState<boolean>(true)

  const [selectedConfirmJobs, setSelectedConfirmJobs] =
    useState<string>("false")
  const [jobREmail, setjobREmail] = useState<string>("")
  const [siteData, setSiteData] = useState<Site[]>([])
  const [selectedSite, setSelectedSite] = useState<string>("") //app_form_Data.Job.Site.site_id
  const emailRegex = /^[a-zA-Z0-9._-]+@betagro\.com$/

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const isNumeric = (value: string): boolean => {
    return /^-?\d+$/.test(value)
  }

  const validateThaiCitizenID = async (id: string) => {
    // Remove any non-digit characters from the ID
    id = id.replace(/\D/g, "")

    // Check if the ID has the correct length (13 digits)
    if (id.length !== 13) {
      return false
    }

    // Convert the ID to an array of digits
    const digits = id.split("").map(Number)

    // Calculate the checksum
    let sum = 0
    for (let i = 0; i < 12; i++) {
      sum += digits[i] * (13 - i)
    }

    // Check if the last digit matches the checksum
    const checksum = (11 - (sum % 11)) % 10
    return digits[12] === checksum
  }

  const checkIDCard = async (id: string) => {
    const validateStatus = await validateThaiCitizenID(id)
    if (validateStatus && isNumeric(id)) {
      setStatusValidate(true)
    } else {
      setStatusValidate(false)
    }
  }

  useEffect(() => {
    if (open) {
      const fetchECData = async () => {
        try {
          const response = await getECDataById(ec_data_id)
          const ECData = response.data.ECData

          if (response.data?.success) {
            setNationalID(ECData.national_id)
            setCountryOfNationalID(ECData.country_of_national_id)
            setNationalIDCardType(ECData.national_id_card_type)
            setCountryOfBirth(ECData.country_of_birth)
            setDateOfBirth(dayjs(ECData.date_of_birth, "DD/MM/YYYY"))
            setPrefix(ECData.prefix)
            setFirstNameTH(ECData.first_name_th)
            setLastNameTH(ECData.last_name_th)
            setFirstNameEN(ECData.first_name_en)
            setLastNameEN(ECData.last_name_en)
            setGender(ECData.gender)
            setNationality(ECData.nationality)
            setMaritalStatus(ECData.marital_status)
            setReligion(ECData.religion)
            setPhoneNumber(ECData.phone_number)
            setjobREmail(ECData.job_relationship_email)
            setSelectedSite(ECData.site_id)

            setLegalCountry(ECData.legal_country.toString())
            setLegalHouseNumber(ECData.legal_house_number)
            setLegalMoo(ECData.legal_moo ? ECData.legal_moo : "")
            setLegalStreet(ECData.legal_street ? ECData.legal_street : "")
            setLegalLane(ECData.legal_lane ? ECData.legal_lane : "")
            setLegalSubDistrict(ECData.legal_sub_district)
            setLegalDistrict(ECData.legal_district)
            setLegalProvince(ECData.legal_province)
            setLegalPostalCode(ECData.legal_postal_code)

            setSameAsLegal(ECData.same_as_legal === "Yes" ? true : false)

            setMailingCountry(ECData.mailing_country.toString())
            setMailingHouseNumber(ECData.mailing_house_number)
            setMailingMoo(ECData.mailing_moo ? ECData.mailing_moo : "")
            setMailingStreet(ECData.mailing_street ? ECData.mailing_street : "")
            setMailingLane(ECData.mailing_lane ? ECData.mailing_lane : "")
            setMailingSubDistrict(ECData.mailing_sub_district)
            setMailingDistrict(ECData.mailing_district)
            setMailingProvince(ECData.mailing_province)
            setMailingPostalCode(ECData.mailing_postal_code)

            setSelectedHireDate(dayjs(ECData.hire_date, "DD/MM/YYYY"))
            setPositionId(ECData.position)
            setSelectedCompany(ECData.company)
            setSelectedIncentiveGroup(ECData.incentive_group)
            setSelectedPayGrade(ECData.pay_grade)
            setSelectedWorkSchedule(ECData.work_schedule)
            setSelectedHolidaySchedule(ECData.holiday_schedule)
            setSelectedWorkGroup(ECData.work_group)
            setSelectedContractStartDate(
              ECData.contract_start_date
                ? dayjs(ECData.contract_start_date, "DD/MM/YYYY")
                : null
            )
            setSelectedContractEndDate(
              ECData.contract_end_date
                ? dayjs(ECData.contract_end_date, "DD/MM/YYYY")
                : null
            )
            setSelectedBank(ECData.bank ? ECData.bank : "")
            setBankAccountNumber(
              ECData.bank_account_number ? ECData.bank_account_number : ""
            )
            setSelectedPayGroup(ECData.pay_group)
            setAmount101(ECData.amount_101 ? ECData.amount_101.toString() : "")
            setAmount102(ECData.amount_102 ? ECData.amount_102.toString() : "")
            setAmount103_1(
              ECData.amount_103_1 ? ECData.amount_103_1.toString() : ""
            )
            setAmount125(ECData.amount_125 ? ECData.amount_125.toString() : "")
            setAmount128(ECData.amount_128 ? ECData.amount_128.toString() : "")
            setAmount133(ECData.amount_133 ? ECData.amount_133.toString() : "")
            setAmount163(ECData.amount_163 ? ECData.amount_163.toString() : "")
          }
        } catch (error) {
          console.log(error)
          navigate("/servererror", { replace: true })
        } finally {
          setLoadingECData(false)
        }
      }

      fetchECData()
    }
  }, [navigate, open, ec_data_id])

  useEffect(() => {
    const fetchAllActiveCountry = async () => {
      try {
        const response = await getAllActiveCountry()

        if (response.data?.success) {
          setCountryList(response.data.countries)
        }
      } catch (error) {
        console.log(error)
        navigate("/servererror", { replace: true })
      } finally {
        setLoadingCountryList(false)
      }
    }

    const fetchAllActiveReligion = async () => {
      try {
        const response = await getAllActiveReligion()

        if (response.data?.success) {
          setReligionList(response.data?.religions)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoadingReligionList(false)
      }
    }

    const fetchAllActiveMaritalStatus = async () => {
      try {
        const response = await getAllActiveMaritalStatus()

        if (response.data?.success) {
          setMaritalStatusList(response.data?.marital_statuses)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoadingMaritalStatusList(false)
      }
    }

    const fetchAllActiveCompany = async () => {
      try {
        const response = await getAllActiveCompany()

        if (response.data?.success) {
          setCompanyList(response.data.companies)
        }
      } catch (error) {
        console.log(error)
        navigate("/servererror", { replace: true })
      } finally {
        setLoadingCompanyList(false)
      }
    }

    const fetchAllActiveIncentiveGroup = async () => {
      try {
        const response = await getAllActiveIncentiveGroup()

        if (response.data?.success) {
          setIncentiveGroupList(response.data.incentiveGroups)
        }
      } catch (error) {
        console.log(error)
        navigate("/servererror", { replace: true })
      } finally {
        setLoadingIncentiveGroupList(false)
      }
    }

    const fetchAllActivePayGrade = async () => {
      try {
        const response = await getAllActivePayGrade()

        if (response.data?.success) {
          setPayGradeList(response.data.payGrades)
        }
      } catch (error) {
        console.log(error)
        navigate("/servererror", { replace: true })
      } finally {
        setLoadingPayGradeList(false)
      }
    }

    const fetchAllActiveWorkSchedule = async () => {
      try {
        const response = await getAllActiveWorkSchedule()

        if (response.data?.success) {
          setWorkScheduleList(response.data.workSchedules)
        }
      } catch (error) {
        console.log(error)
        navigate("/servererror", { replace: true })
      } finally {
        setLoadingWorkScheduleList(false)
      }
    }

    const fetchAllActiveHolidaySchedule = async () => {
      try {
        const response = await getAllActiveHolidaySchedule()

        if (response.data?.success) {
          setHolidayScheduleList(response.data.holidaySchedules)
        }
      } catch (error) {
        console.log(error)
        navigate("/servererror", { replace: true })
      } finally {
        setLoadingHolidayScheduleList(false)
      }
    }

    const fetchAllActiveWorkGroup = async () => {
      try {
        const response = await getAllActiveWorkGroup()

        if (response.data?.success) {
          setWorkGroupList(response.data.workGroups)
        }
      } catch (error) {
        console.log(error)
        navigate("/servererror", { replace: true })
      } finally {
        setLoadingWorkGroupList(false)
      }
    }

    const fetchAllActiveBank = async () => {
      try {
        const response = await getAllActiveBank()

        if (response.data?.success) {
          setBankList(response.data.banks)
        }
      } catch (error) {
        console.log(error)
        navigate("/servererror", { replace: true })
      } finally {
        setLoadingBankList(false)
      }
    }

    const fetchAllActivePayGroup = async () => {
      try {
        const response = await getAllActivePayGroup()

        if (response.data?.success) {
          setPayGroupList(response.data.payGroups)
        }
      } catch (error) {
        console.log(error)
        navigate("/servererror", { replace: true })
      } finally {
        setLoadingPayGroupList(false)
      }
    }
    const fetchActivePrefix = async () => {
      try {
        const response = await getAllActivePrefix()

        if (response.data?.success) {
          setPrefixList(response.data?.marital_statuses)
        }
      } catch (error) {
        console.log(error)
      }
    }
    const fetchSites = async () => {
      try {
        const response = await getAllSites()

        if (response.data?.success) {
          const sitesData = response.data.sites
          const dataSort = sitesData.sort((a: Site, b: Site) =>
            a.site_id.localeCompare(b.site_id)
          )
          setSiteData(dataSort.filter((site: Site) => site.is_active === true))
        }
      } catch (error) {
        navigate("/servererror", { replace: true })
      }
    }

    if (open) {
      fetchAllActiveCountry()
      fetchAllActiveReligion()
      fetchAllActiveMaritalStatus()
      fetchAllActiveCompany()
      fetchAllActiveIncentiveGroup()
      fetchAllActivePayGrade()
      fetchAllActiveWorkSchedule()
      fetchAllActiveHolidaySchedule()
      fetchAllActiveWorkGroup()
      fetchAllActiveBank()
      fetchAllActivePayGroup()
      fetchActivePrefix()
      fetchSites()
    }
  }, [navigate, open])

  const onSubmitted = async () => {
    // validate id
    const checkBank =
      selectedBank.length <= 0
        ? true
        : selectedBank.length > 0 && bankAccountNumber.length === 10
        ? true
        : false
    const checkEmail = emailRegex.test(jobREmail.toLowerCase())
    if (statusValidate && checkBank && checkEmail) {
      try {
        setSubmittedLoading(true)
        const parsedToken: TokenPayload = jwtDecode(localStorage.access_token)

        const ECData = {
          ec_data_id: ec_data_id,
          national_id: nationalID,
          country_of_national_id: countryOfNationalID,
          national_id_card_type: nationalIDCardType,
          country_of_birth: countryOfBirth,
          date_of_birth: dayjs(dateOfBirth).format("DD/MM/YYYY"),
          prefix: prefix,
          first_name_th: firstNameTH,
          last_name_th: lastNameTH,
          first_name_en: firstNameEN,
          last_name_en: lastNameEN,
          gender: gender,
          nationality: nationality,
          marital_status: maritalStatus,
          religion: religion,
          phone_number: phoneNumber,
          job_relationship_email: jobREmail,
          site_id: selectedSite,
          legal_country: legalCountry,
          legal_house_number: legalHouseNumber,
          legal_moo: legalMoo,
          legal_street: legalStreet,
          legal_lane: legalLane,
          legal_sub_district: legalSubDistrict,
          legal_district: legalDistrict,
          legal_province: legalProvince,
          legal_postal_code: legalPostalCode,
          same_as_legal: sameAsLegal ? "Yes" : "No",
          mailing_country: mailingCountry,
          mailing_house_number: mailingHouseNumber,
          mailing_moo: mailingMoo,
          mailing_street: mailingStreet,
          mailing_lane: mailingLane,
          mailing_sub_district: mailingSubDistrict,
          mailing_district: mailingDistrict,
          mailing_province: mailingProvince,
          mailing_postal_code: mailingPostalCode,
          hire_date: dayjs(selectedHireDate).format("DD/MM/YYYY"),
          position_id: positionId,
          company: selectedCompany,
          incentive_group: selectedIncentiveGroup,
          pay_grade: selectedPayGrade,
          work_schedule: selectedWorkSchedule,
          holiday_schedule: selectedHolidaySchedule,
          work_group: selectedWorkGroup,
          contract_start_date: selectedContractStartDate
            ? dayjs(selectedContractStartDate).format("DD/MM/YYYY")
            : null,
          contract_end_date: selectedContractEndDate
            ? dayjs(selectedContractEndDate).format("DD/MM/YYYY")
            : null,
          bank: selectedBank,
          bank_account_number: bankAccountNumber,
          pay_group: selectedPayGroup,
          amount_101: Amount101,
          amount_102: Amount102,
          amount_103_1: Amount103_1,
          amount_125: Amount125,
          amount_128: Amount128,
          amount_133: Amount133,
          amount_163: Amount163,
          modified_by: parsedToken.Username,
        }

        const response = await editECData(ECData)

        if (response.data?.success) {
          onClose(true)

          Swal.fire({
            icon: "success",
            title: `${t("Success")}`,
            text: `${t("Ecsuccess")}`,
            confirmButtonText: `${t("ok")}`,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload()
            }
          })
        } else {
          Swal.fire({
            icon: "error",
            title: `${t("Error")}`,
            text: `${t("ECfailed")}`,
            confirmButtonText: `${t("ok")}`,
          })
        }
      } catch (error) {
        console.log(error)
        Swal.fire({
          icon: "error",
          title: `${t("Error")}`,
          text: `${t("Please try again")}`,
          confirmButtonText: `${t("ok")}`,
        })
      } finally {
        setSubmittedLoading(false)
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "100%", position: "relative" } }}
        maxWidth="lg"
        open={open}
        {...other}
      >
        {!allSuccessLoading ? (
          <>
            <DialogContent sx={{ height: "80vh" }}>
              <CircularProgress
                size={50}
                sx={{
                  color: green[500],
                  position: "absolute",
                  zIndex: 999,
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle
              id="scroll-dialog-title"
              sx={{ fontWeight: "bold", fontSize: { xs: 16, sm: 18 } }}
            >
              {t("Edit EC Data")}
            </DialogTitle>

            <DialogContent
              dividers
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <DialogTitle
                id="scroll-dialog-title"
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: 14, sm: 16 },
                  px: 0,
                  pt: 0,
                }}
              >
                {t("Personal Informations")}
              </DialogTitle>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="national_id"
                    label={t("ID card number") + " *"}
                    placeholder={t("ID card number")!}
                    {...register("national_id", {
                      required: true,
                      maxLength: 13,
                      minLength: 13,
                    })}
                    value={nationalID}
                    onChange={(event) => {
                      const inputText = event.target.value

                      setNationalID(inputText)

                      errors.national_id = undefined
                      // if (/^\d*$/.test(inputText)) {
                      if (inputText && inputText.length === 13) {
                        checkIDCard(inputText)
                      }
                      // }
                    }}
                    inputProps={{ maxLength: 13 }}
                    helperText={
                      statusValidate === false
                        ? `${t("text_error_validate_idCard")}`
                        : null
                    }
                    error={
                      errors.national_id || statusValidate === false
                        ? true
                        : false
                    }
                  />
                  {errors.national_id?.type === "required" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("ID Card number is required")}
                    </Typography>
                  )}
                  {errors.national_id?.type === "maxLength" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("ID Card number must be 13 digit")}
                    </Typography>
                  )}
                  {errors.national_id?.type === "minLength" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("ID Card number must be 13 digit")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="country_of_national_id"
                      error={errors.country_of_national_id ? true : false}
                    >
                      {t("Country of National ID") + " *"}
                    </InputLabel>
                    <Select
                      error={errors.country_of_national_id ? true : false}
                      required
                      labelId="country_of_national_id"
                      id="country_of_national_id"
                      label={t("Country of National ID") + " *"}
                      {...register("country_of_national_id", {
                        required: true,
                      })}
                      value={countryList.length > 0 ? countryOfNationalID : ""}
                      onChange={(event: SelectChangeEvent) => {
                        setCountryOfNationalID(event.target.value as string)
                        setNationalIDCardType(
                          (event.target.value as string) === "THA"
                            ? "tni"
                            : "pid"
                        )
                        errors.country_of_national_id = undefined
                      }}
                      MenuProps={{ sx: { maxHeight: 500 } }}
                    >
                      {countryList.length > 0 &&
                        countryList.map(
                          (item: {
                            country_code: string
                            country_name: string
                          }) => {
                            return (
                              <MenuItem
                                key={item.country_code}
                                value={item.country_code}
                              >
                                {item.country_name}
                              </MenuItem>
                            )
                          }
                        )}
                    </Select>
                  </FormControl>
                  {errors.country_of_national_id?.type === "required" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("Country of National ID is required")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="national_id_card_type"
                      error={errors.national_id_card_type ? true : false}
                    >
                      {t("National ID Card Type") + " *"}
                    </InputLabel>
                    <Select
                      error={errors.national_id_card_type ? true : false}
                      required
                      sx={{
                        backgroundColor: "rgba(0, 0, 0, 0.05)",
                        "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                          {
                            WebkitTextFillColor: "rgba(0, 0, 0, 0.7)",
                          },
                      }}
                      disabled
                      labelId="national_id_card_type"
                      id="national_id_card_type"
                      label={t("National ID Card Type") + " *"}
                      {...register("national_id_card_type")}
                      value={countryOfNationalID === "THA" ? "tni" : "pid"}
                      onChange={(event: SelectChangeEvent) => {
                        setNationalIDCardType(event.target.value as string)
                        errors.national_id_card_type = undefined
                      }}
                    >
                      <MenuItem value="pid">pid</MenuItem>
                      <MenuItem value="tni">tni</MenuItem>
                    </Select>
                  </FormControl>
                  {errors.national_id_card_type?.type === "required" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("National ID Card Type is required")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="country_of_birth"
                      error={errors.country_of_birth ? true : false}
                    >
                      {t("Country of Birth") + " *"}
                    </InputLabel>
                    <Select
                      error={errors.country_of_birth ? true : false}
                      required
                      labelId="country_of_birth"
                      id="country_of_birth"
                      label={t("Country of Birth") + " *"}
                      {...register("country_of_birth", { required: true })}
                      value={countryList.length > 0 ? countryOfBirth : ""}
                      onChange={(event: SelectChangeEvent) => {
                        setCountryOfBirth(event.target.value as string)
                        errors.country_of_birth = undefined
                      }}
                      MenuProps={{ sx: { maxHeight: 500 } }}
                    >
                      {countryList.length > 0 &&
                        countryList.map(
                          (item: {
                            country_code: string
                            country_name: string
                          }) => {
                            return (
                              <MenuItem
                                key={item.country_code}
                                value={item.country_code}
                              >
                                {item.country_name}
                              </MenuItem>
                            )
                          }
                        )}
                    </Select>
                  </FormControl>
                  {errors.country_of_birth?.type === "required" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("Country of Birth is required")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider
                    dateAdapter={newAdapter}
                    adapterLocale="th"
                  >
                    <MobileDatePicker
                      sx={{ width: "100%" }}
                      label={t("Date of Birth") + " *"}
                      format="DD/MM/YYYY"
                      {...register("date_of_birth", { required: true })}
                      closeOnSelect
                      localeText={{
                        okButtonLabel: `${t("ok")}`,
                        cancelButtonLabel: `${t("Cancel")}`,
                      }}
                      value={dateOfBirth}
                      dayOfWeekFormatter={(_day, weekday) =>
                        `${weekday.format("dd")}.`
                      }
                      onChange={(newValue) => {
                        try {
                          if (newValue !== null) {
                            setDateOfBirth(dayjs(newValue))
                          }
                        } catch (error) {}
                      }}
                      slotProps={{
                        textField: {
                          error: errors.date_of_birth ? true : false,
                        },
                      }}
                    />
                  </LocalizationProvider>
                  {errors.date_of_birth?.type === "required" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("Date of birth is required")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="prefix"
                      error={errors.prefix ? true : false}
                    >
                      {t("Prefix") + " *"}
                    </InputLabel>
                    <Select
                      error={errors.prefix ? true : false}
                      required
                      labelId="prefix"
                      id="prefix"
                      label={t("Prefix") + " *"}
                      {...register("prefix", { required: true })}
                      value={prefix}
                      onChange={(event: SelectChangeEvent) => {
                        setPrefix(event.target.value as string)
                        setGender(
                          (event.target.value as string) === "Miss" ||
                            (event.target.value as string) === "Mrs" ||
                            event.target.value.indexOf("ญ") > 0
                            ? "F"
                            : "M"
                        )
                        errors.prefix = undefined
                      }}
                      MenuProps={{ sx: { maxHeight: 500 } }}
                    >
                      {prefixList.map(
                        (item: { external_code: string; label: string }) => {
                          return (
                            <MenuItem
                              key={item.external_code}
                              value={item.external_code}
                            >
                              {t(`${item.label}`)}{" "}
                            </MenuItem>
                          )
                        }
                      )}
                      {/* <MenuItem value='Mr'>{t("personal_data.gender.mr_label")}</MenuItem>
                                            <MenuItem value='Mrs'>{t("personal_data.gender.mrs_label")}</MenuItem>
                                            <MenuItem value='Miss'>{t("personal_data.gender.miss_label")}</MenuItem> */}
                    </Select>
                  </FormControl>
                  {errors.prefix?.type === "required" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("Prefix is required")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="first_name_th"
                    label={t("First name (TH)") + " *"}
                    placeholder={t("First name (TH)")!}
                    {...register("first_name_th", { required: true })}
                    value={firstNameTH}
                    onChange={(event) => {
                      setFirstNameTH(event.target.value)
                      errors.first_name_th = undefined
                    }}
                    error={errors.first_name_th ? true : false}
                  />
                  {errors.first_name_th?.type === "required" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("Thai first name is required")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="last_name_th"
                    label={t("Last name (TH)") + " *"}
                    placeholder={t("Last name (TH)")!}
                    {...register("last_name_th", { required: true })}
                    value={lastNameTH}
                    onChange={(event) => {
                      setLastNameTH(event.target.value)
                      errors.last_name_th = undefined
                    }}
                    error={errors.last_name_th ? true : false}
                  />
                  {errors.last_name_th?.type === "required" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("Thai last name is required")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="first_name_en"
                    label={t("First name (EN)") + " *"}
                    placeholder={t("First name (EN)")!}
                    {...register("first_name_en", { required: true })}
                    value={firstNameEN}
                    onChange={(event) => {
                      setFirstNameEN(event.target.value)
                      errors.first_name_en = undefined
                    }}
                    error={errors.first_name_en ? true : false}
                  />
                  {errors.first_name_en?.type === "required" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("English first name is required")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="last_name_en"
                    label={t("Last name (EN)") + " *"}
                    placeholder={t("Last name (EN)")!}
                    {...register("last_name_en", { required: true })}
                    value={lastNameEN}
                    onChange={(event) => {
                      setLastNameEN(event.target.value)
                      errors.last_name_en = undefined
                    }}
                    error={errors.last_name_en ? true : false}
                  />
                  {errors.last_name_en?.type === "required" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("English last Name is required")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="gender"
                      error={errors.gender ? true : false}
                    >
                      {t("Gender") + " *"}
                    </InputLabel>
                    <Select
                      error={errors.gender ? true : false}
                      required
                      labelId="gender"
                      id="gender"
                      label={t("Gender") + " *"}
                      onChange={(event: SelectChangeEvent) => {
                        setGender(event.target.value as string)
                        errors.gender = undefined
                      }}
                      value={gender}
                    >
                      <MenuItem autoFocus value="M">
                        {t("Male")}
                      </MenuItem>
                      <MenuItem autoFocus value="F">
                        {t("Female")}
                      </MenuItem>
                      {/* {
                                                prefix === 'Mr' ?
                                                    <MenuItem value='M'>{t("Male")}</MenuItem> :
                                                    (prefix === 'Mrs' || prefix === 'Miss') ?
                                                        <MenuItem value='F'>{t("Female")}</MenuItem> :
                                                        <MenuItem value=''>{t("Please select prefix before")}</MenuItem>
                                            } */}
                    </Select>
                  </FormControl>
                  {errors.gender?.type === "required" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("Gender is required")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="nationality"
                      error={errors.nationality ? true : false}
                    >
                      {t("Nationality") + " *"}
                    </InputLabel>
                    <Select
                      error={errors.nationality ? true : false}
                      required
                      labelId="nationality"
                      id="nationality"
                      label={t("Nationality") + " *"}
                      onChange={(event: SelectChangeEvent) => {
                        setNationality(event.target.value as string)
                        errors.nationality = undefined
                      }}
                      value={countryList.length > 0 ? nationality : ""}
                    >
                      {countryList.length > 0 &&
                        countryList.map(
                          (item: {
                            country_code: string
                            country_name: string
                          }) => {
                            return (
                              <MenuItem
                                key={item.country_code}
                                value={item.country_code}
                              >
                                {item.country_code}
                              </MenuItem>
                            )
                          }
                        )}
                    </Select>
                  </FormControl>
                  {errors.nationality?.type === "required" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("Nationality is required")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="marital_status"
                      error={errors.marital_status ? true : false}
                    >
                      {t("MaritalStatus") + " *"}
                    </InputLabel>
                    <Select
                      error={errors.marital_status ? true : false}
                      required
                      labelId="marital_status"
                      id="marital_status"
                      label={t("MaritalStatus") + " *"}
                      onChange={(event: SelectChangeEvent) => {
                        setMaritalStatus(event.target.value as string)
                        errors.marital_status = undefined
                      }}
                      value={maritalStatusList.length > 0 ? maritalStatus : ""}
                    >
                      {maritalStatusList.length > 0 &&
                        maritalStatusList.map(
                          (item: { external_code: string; label: string }) => {
                            return (
                              <MenuItem
                                key={item.external_code}
                                value={item.external_code}
                              >
                                {t(item.label)}
                              </MenuItem>
                            )
                          }
                        )}
                    </Select>
                  </FormControl>
                  {errors.marital_status?.type === "required" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("Nationality is required")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="religion"
                      error={errors.religion ? true : false}
                    >
                      {t("Religion") + " *"}
                    </InputLabel>
                    <Select
                      error={errors.religion ? true : false}
                      required
                      labelId="religion"
                      id="religion"
                      label={t("Religion") + " *"}
                      onChange={(event: SelectChangeEvent) => {
                        setReligion(event.target.value as string)
                        errors.religion = undefined
                      }}
                      value={religionList.length > 0 ? religion : ""}
                    >
                      {religionList.length > 0 &&
                        religionList.map(
                          (item: {
                            external_code: string
                            label_th?: string
                            label_en?: string
                          }) => {
                            return (
                              <MenuItem
                                key={item.external_code}
                                value={item.external_code}
                              >
                                {i18n.language === "th"
                                  ? item.label_th
                                  : item.label_en}
                              </MenuItem>
                            )
                          }
                        )}
                    </Select>
                  </FormControl>
                  {errors.religion?.type === "required" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("Nationality is required")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="phone_number"
                    label={t("Phone Number") + " *"}
                    placeholder={t("Phone Number")!}
                    {...register("phone_number", { required: true })}
                    value={phoneNumber}
                    onChange={(event) => {
                      setPhoneNumber(event.target.value)
                      errors.phone_number = undefined
                    }}
                    error={errors.phone_number ? true : false}
                  />
                  {errors.phone_number?.type === "required" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("Phone Number is required")}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <DialogTitle
                id="scroll-dialog-title"
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: 14, sm: 16 },
                  px: 0,
                  pt: 4,
                  pb: 3,
                }}
              >
                {t("Address Informations")}
              </DialogTitle>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Typography
                    variant="inherit"
                    color="text.secondary"
                    align="left"
                    pb={0}
                    fontSize={14}
                  >
                    {t("Legal Address")}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="legal_country">
                      {t("Legal Country")}
                    </InputLabel>
                    <Select
                      required
                      labelId="legal_country"
                      id="legal_country"
                      label={t("Legal Country")}
                      {...register("legal_country")}
                      value={countryList.length > 0 ? legalCountry : ""}
                      onChange={(event: SelectChangeEvent) => {
                        setLegalCountry(event.target.value as string)
                        setLegalProvince("")
                      }}
                      MenuProps={{ sx: { maxHeight: 500 } }}
                    >
                      {countryList.length > 0 &&
                        countryList.map(
                          (item: {
                            country_code: string
                            country_name: string
                          }) => {
                            return (
                              <MenuItem
                                key={item.country_code}
                                value={item.country_code}
                              >
                                {item.country_name}
                              </MenuItem>
                            )
                          }
                        )}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="legal_house_number"
                    label={t("Legal House Number")}
                    placeholder={t("Legal House Number")!}
                    {...register("legal_house_number")}
                    value={legalHouseNumber}
                    onChange={(event) => {
                      setLegalHouseNumber(event.target.value)
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="legal_moo"
                    label={t("Legal Moo")}
                    placeholder={t("Legal Moo")!}
                    {...register("legal_moo")}
                    value={legalMoo}
                    onChange={(event) => {
                      setLegalMoo(event.target.value)
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="legal_street"
                    label={t("Legal Street")}
                    placeholder={t("Legal Street")!}
                    {...register("legal_street")}
                    value={legalStreet}
                    onChange={(event) => {
                      setLegalStreet(event.target.value)
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="legal_lane"
                    label={t("Legal Lane")}
                    placeholder={t("Legal Lane")!}
                    {...register("legal_lane")}
                    value={legalLane}
                    onChange={(event) => {
                      setLegalLane(event.target.value)
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="legal_province">
                      {t("Legal Province")}
                    </InputLabel>
                    <Select
                      labelId="legal_province"
                      id="legal_province"
                      label={t("Legal Province")}
                      {...register("legal_province")}
                      value={THAProvinceJSON.length > 0 ? legalProvince : ""}
                      onChange={(event: SelectChangeEvent) => {
                        setLegalProvince(event.target.value as string)
                      }}
                      MenuProps={{ sx: { maxHeight: 500 } }}
                    >
                      {legalCountry === "THA" &&
                        THAProvinceJSON.length > 0 &&
                        THAProvinceJSON.map(
                          (item: {
                            external_code: number
                            label_th: string
                          }) => {
                            return (
                              <MenuItem
                                key={item.external_code}
                                value={item.external_code}
                              >
                                {item.label_th}
                              </MenuItem>
                            )
                          }
                        )}
                      {legalCountry === "LAO" &&
                        LAOProvinceJSON.length > 0 &&
                        LAOProvinceJSON.map(
                          (item: {
                            external_code: number | string
                            label: string
                          }) => {
                            return (
                              <MenuItem
                                key={item.external_code}
                                value={item.external_code}
                              >
                                {item.label}
                              </MenuItem>
                            )
                          }
                        )}
                      {legalCountry === "KHM" &&
                        KHMProvinceJSON.length > 0 &&
                        KHMProvinceJSON.map(
                          (item: {
                            external_code: number | string
                            label: string
                          }) => {
                            return (
                              <MenuItem
                                key={item.external_code}
                                value={item.external_code}
                              >
                                {item.label}
                              </MenuItem>
                            )
                          }
                        )}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="legal_district"
                    label={t("Legal District")}
                    placeholder={t("Legal District")!}
                    {...register("legal_district")}
                    value={legalDistrict}
                    onChange={(event) => {
                      setLegalDistrict(event.target.value)
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="legal_sub_district"
                    label={t("Legal Sub District")}
                    placeholder={t("Legal Sub District")!}
                    {...register("legal_sub_district")}
                    value={legalSubDistrict}
                    onChange={(event) => {
                      setLegalSubDistrict(event.target.value)
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="legal_postal_code"
                    label={t("Legal Postal Code")}
                    placeholder={t("Legal Postal Code")!}
                    {...register("legal_postal_code")}
                    value={legalPostalCode}
                    onChange={(event) => {
                      setLegalPostalCode(event.target.value)
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "32px",
                      mt: 4,
                    }}
                  >
                    <Typography
                      variant="inherit"
                      color="text.secondary"
                      align="left"
                      fontSize={14}
                    >
                      {t("Mailing Address")}
                    </Typography>

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sameAsLegal}
                          onChange={(event) => {
                            setSameAsLegal(event.target.checked)
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label={t("Same as legal address")}
                    />
                  </Box>
                </Grid>

                {sameAsLegal ? null : (
                  <>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel id="mailing_country">
                          {t("Mailing Country")}
                        </InputLabel>
                        <Select
                          required
                          labelId="mailing_country"
                          id="mailing_country"
                          label={t("Mailing Country")}
                          {...register("mailing_country")}
                          value={countryList.length > 0 ? mailingCountry : ""}
                          onChange={(event: SelectChangeEvent) => {
                            setMailingCountry(event.target.value as string)
                            setMailingProvince("")
                          }}
                          MenuProps={{ sx: { maxHeight: 500 } }}
                        >
                          {countryList.length > 0 &&
                            countryList.map(
                              (item: {
                                country_code: string
                                country_name: string
                              }) => {
                                return (
                                  <MenuItem
                                    key={item.country_code}
                                    value={item.country_code}
                                  >
                                    {item.country_name}
                                  </MenuItem>
                                )
                              }
                            )}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="mailing_house_number"
                        label={t("Mailing House Number")}
                        placeholder={t("Mailing House Number")!}
                        {...register("mailing_house_number")}
                        value={mailingHouseNumber}
                        onChange={(event) => {
                          setMailingHouseNumber(event.target.value)
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="mailing_moo"
                        label={t("Mailing Moo")}
                        placeholder={t("Mailing Moo")!}
                        {...register("mailing_moo")}
                        value={mailingMoo}
                        onChange={(event) => {
                          setMailingMoo(event.target.value)
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="mailing_street"
                        label={t("Mailing Street")}
                        placeholder={t("Mailing Street")!}
                        {...register("mailing_street")}
                        value={mailingStreet}
                        onChange={(event) => {
                          setMailingStreet(event.target.value)
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="mailing_lane"
                        label={t("Mailing Lane")}
                        placeholder={t("Mailing Lane")!}
                        {...register("mailing_lane")}
                        value={mailingLane}
                        onChange={(event) => {
                          setMailingLane(event.target.value)
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel id="mailing_province">
                          {t("Mailing Province")}
                        </InputLabel>
                        <Select
                          required
                          labelId="mailing_province"
                          id="mailing_province"
                          label={t("Mailing Province")}
                          {...register("mailing_province")}
                          value={
                            THAProvinceJSON.length > 0 ? mailingProvince : ""
                          }
                          onChange={(event: SelectChangeEvent) => {
                            setMailingProvince(event.target.value as string)
                          }}
                          MenuProps={{ sx: { maxHeight: 500 } }}
                        >
                          {mailingCountry === "THA" &&
                            THAProvinceJSON.length > 0 &&
                            THAProvinceJSON.map(
                              (item: {
                                external_code: number
                                label_th: string
                              }) => {
                                return (
                                  <MenuItem
                                    key={item.external_code}
                                    value={item.external_code}
                                  >
                                    {item.label_th}
                                  </MenuItem>
                                )
                              }
                            )}
                          {mailingCountry === "LAO" &&
                            LAOProvinceJSON.length > 0 &&
                            LAOProvinceJSON.map(
                              (item: {
                                external_code: number | string
                                label: string
                              }) => {
                                return (
                                  <MenuItem
                                    key={item.external_code}
                                    value={item.external_code}
                                  >
                                    {item.label}
                                  </MenuItem>
                                )
                              }
                            )}
                          {mailingCountry === "KHM" &&
                            KHMProvinceJSON.length > 0 &&
                            KHMProvinceJSON.map(
                              (item: {
                                external_code: number | string
                                label: string
                              }) => {
                                return (
                                  <MenuItem
                                    key={item.external_code}
                                    value={item.external_code}
                                  >
                                    {item.label}
                                  </MenuItem>
                                )
                              }
                            )}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="mailing_district"
                        label={t("Mailing District")}
                        placeholder={t("Mailing District")!}
                        {...register("mailing_district")}
                        value={mailingDistrict}
                        onChange={(event) => {
                          setMailingDistrict(event.target.value)
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="mailing_sub_district"
                        label={t("Mailing Sub District")}
                        placeholder={t("Mailing Sub District")!}
                        {...register("mailing_sub_district")}
                        value={mailingSubDistrict}
                        onChange={(event) => {
                          setMailingSubDistrict(event.target.value)
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="mailing_postal_code"
                        label={t("Mailing Postal Code")}
                        placeholder={t("Mailing Postal Code")!}
                        {...register("mailing_postal_code")}
                        value={mailingPostalCode}
                        onChange={(event) => {
                          setMailingPostalCode(event.target.value)
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>

              <DialogTitle
                id="scroll-dialog-title"
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: 14, sm: 16 },
                  px: 0,
                  pt: 4,
                  pb: 3,
                }}
              >
                {t("Job information")}
              </DialogTitle>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider
                    dateAdapter={newAdapter}
                    adapterLocale="th"
                  >
                    <MobileDatePicker
                      sx={{ width: "100%" }}
                      label={t("Hire Date") + " *"}
                      format="DD/MM/YYYY"
                      {...register("hire_date", { required: true })}
                      closeOnSelect
                      localeText={{
                        okButtonLabel: `${t("ok")}`,
                        cancelButtonLabel: `${t("Cancel")}`,
                      }}
                      value={!loadingECData ? selectedHireDate : null}
                      dayOfWeekFormatter={(_day, weekday) =>
                        `${weekday.format("dd")}.`
                      }
                      onChange={(newValue) => {
                        try {
                          if (newValue !== null) {
                            setSelectedHireDate(dayjs(newValue))
                          }
                        } catch (error) {}
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      id="job_relationship_email"
                      label={t("Job Relationship Email") + " *"}
                      placeholder={t("Job Relationship Email")!}
                      {...register("job_relationship_email", {
                        required: selectedConfirmJobs === "true" ? false : true,
                      })}
                      value={jobREmail}
                      onChange={(event) => {
                        setjobREmail(event.target.value)
                        setSelectedConfirmJobs(
                          event.target.value.length > 0 ? "true" : "false"
                        )
                        errors.job_relationship_email = undefined
                      }}
                      error={errors.job_relationship_email ? true : false}
                    />
                    {selectedConfirmJobs === "true" &&
                      emailRegex.test(jobREmail.toLowerCase()) === false && (
                        <Typography
                          variant="inherit"
                          color="error"
                          align="left"
                          pt={1}
                          ml={1}
                          fontSize={13}
                        >
                          {t(
                            "Accounts can only be created with Betagro email addresses"
                          )}
                        </Typography>
                      )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="position_id"
                    label={t("No. position") + " *"}
                    placeholder={t("No. position")!}
                    {...register("position_id", {
                      required: true,
                      maxLength: 8,
                      minLength: 8,
                    })}
                    value={positionId}
                    onChange={(event) => {
                      setPositionId(event.target.value)
                      errors.position_id = undefined
                    }}
                    inputProps={{ maxLength: 8 }}
                    error={errors.position_id ? true : false}
                  />
                  {errors.position_id?.type === "required" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("No. position is required")}
                    </Typography>
                  )}
                  {errors.position_id?.type === "maxLength" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("No. position must be at least 8 characters")}
                    </Typography>
                  )}
                  {errors.position_id?.type === "minLength" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("No. position must be at least 8 characters")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                  <InputLabel id="site" >
                      {t("Site") + " *"}
                    </InputLabel>
                    <Select
                      labelId="site"
                      id="site"
                      label={t("Site") + " *"}
                      {...register("site")}
                      value={selectedSite}
                      onChange={(event: SelectChangeEvent) => {
                        setSelectedSite(event.target.value as string)
                        errors.site = undefined
                      }}
                      MenuProps={{ sx: { maxHeight: 500 } }}
                    >
                      {siteData.length > 0 &&
                        siteData.map(
                          (item: { site_id: string; site_name: string }) => {
                            return (
                              <MenuItem
                                key={item.site_id}
                                value={item.site_id}
                              >{`${item.site_id} - ${item.site_name}`}</MenuItem>
                            )
                          }
                        )}
                    </Select>
                  </FormControl>
                    {/* <InputLabel id="site" error={errors.site ? true : false}>
                      {t("Site") + " *"}
                    </InputLabel>
                    <Select
                      error={errors.site ? true : false}
                      required
                      labelId="site"
                      id="site"
                      label={t("Site") + " *"}
                      {...register("site", { required: true })}
                      value={selectedSite}
                      onChange={(event: SelectChangeEvent) => {
                        setSelectedSite(event.target.value as string)
                        errors.site = undefined
                      }}
                      MenuProps={{ sx: { maxHeight: 500 } }}
                    >
                      {siteData.length > 0 &&
                        siteData.map(
                          (item: { site_id: string; site_name: string }) => {
                            return (
                              <MenuItem
                                key={item.site_id}
                                value={item.site_id}
                              >{`${item.site_id} - ${item.site_name}`}</MenuItem>
                            )
                          }
                        )}
                    </Select>
                  </FormControl>
                  {errors.company?.type === "required" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("Company is require")}
                    </Typography>
                  )} */}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    {/* <InputLabel
                      id="company"
                      error={errors.company ? true : false}
                    >
                      {t("Company") + " *"}
                    </InputLabel>
                    <Select
                      error={errors.company ? true : false}
                      required
                      labelId="company"
                      id="company"
                      label={t("Company") + " *"}
                      {...register("company", { required: true })}
                      value={companyList.length > 0 ? selectedCompany : ""}
                      onChange={(event: SelectChangeEvent) => {
                        setSelectedCompany(event.target.value as string)
                        errors.company = undefined
                      }}
                      MenuProps={{ sx: { maxHeight: 500 } }}
                    >
                      {companyList.length > 0 &&
                        companyList.map(
                          (item: {
                            company_code: string
                            company_name: string
                          }) => {
                            return (
                              <MenuItem
                                key={item.company_code}
                                value={item.company_code}
                              >{`${item.company_code} - ${item.company_name}`}</MenuItem>
                            )
                          }
                        )}
                    </Select> */}
                    <Autocomplete
                      onChange={(
                        event,
                        newValue: {
                          company_code: string
                          company_name: string
                        } | null
                      ) => {
                        setSelectedCompany(newValue?.company_code || "")
                      }}
                      options={companyList}
                      getOptionLabel={(option) => option.company_code + " - " + option.company_name}
                      value={
                        companyList.filter(
                          (item) => item.company_code === selectedCompany
                        )[0]
                      }
                      renderOption={(
                        props,
                        option,
                        { inputValue, selected }
                      ) => {
                        const matches = match(option.company_code + " - " + option.company_name, inputValue, {
                          insideWords: true,
                        })
                        const parts = parse(option.company_code + " - " + option.company_name, matches)

                        return (
                          <li {...props}>
                            <div>
                              {parts.map((part: any, index: any) => (
                                <span
                                  key={index}
                                  style={{
                                    fontWeight: part.highlight ? 700 : 400,
                                  }}
                                >
                                  {part.text}
                                </span>
                              ))}
                            </div>
                          </li>
                        )
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t("Company") + " *"}
                          label={t("Company") + " *"}
                        />
                      )}
                    />
                  </FormControl>
                  {errors.company?.type === "required" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("Company is require")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="incentiveGroup"
                      error={errors.incentive_group ? true : false}
                    >
                      {t("Incentive Group") + " *"}
                    </InputLabel>
                    <Select
                      error={errors.incentive_group ? true : false}
                      required
                      labelId="incentiveGroup"
                      id="incentiveGroup"
                      label={t("Incentive Group") + " *"}
                      {...register("incentive_group", { required: true })}
                      value={
                        incentiveGroupList.length > 0
                          ? selectedIncentiveGroup
                          : ""
                      }
                      onChange={(event: SelectChangeEvent) => {
                        setSelectedIncentiveGroup(event.target.value as string)
                        errors.incentive_group = undefined
                      }}
                    >
                      {incentiveGroupList.length > 0 &&
                        incentiveGroupList.map(
                          (item: { external_code: string; label: string }) => {
                            return (
                              <MenuItem
                                key={item.external_code}
                                value={item.external_code}
                              >
                                {item.label}
                              </MenuItem>
                            )
                          }
                        )}
                    </Select>
                  </FormControl>
                  {errors.incentive_group?.type === "required" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("Incentive Group is required")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    {/* <InputLabel
                      id="workSchedule"
                      error={errors.work_schedule ? true : false}
                    >
                      {t("Work Schedule") + " *"}
                    </InputLabel> */}
                    {/* <Select
                      error={errors.work_schedule ? true : false}
                      required
                      labelId="workSchedule"
                      id="workSchedule"
                      label={t("Work Schedule") + " *"}
                      {...register("work_schedule", { required: true })}
                      value={
                        workScheduleList.length > 0 ? selectedWorkSchedule : ""
                      }
                      onChange={(event: SelectChangeEvent) => {
                        setSelectedWorkSchedule(event.target.value as string)
                        errors.work_schedule = undefined
                      }}
                    >
                      {workScheduleList.length > 0 &&
                        workScheduleList.map(
                          (item: { external_code: string; label: string }) => {
                            return (
                              <MenuItem
                                key={item.external_code}
                                value={item.external_code}
                              >
                                {item.label}
                              </MenuItem>
                            )
                          }
                        )}
                    </Select> */}
                    <Autocomplete
                      onChange={(
                        event,
                        newValue: {
                          external_code: string
                          label: string
                        } | null
                      ) => {
                        setSelectedWorkSchedule(newValue?.external_code || "")
                      }}
                      options={workScheduleList}
                      getOptionLabel={(option) => option.label}
                      value={
                        workScheduleList.filter(
                          (item) => item.external_code === selectedWorkSchedule
                        )[0]
                      }
                      renderOption={(
                        props,
                        option,
                        { inputValue, selected }
                      ) => {
                        const matches = match(option.label, inputValue, {
                          insideWords: true,
                        })
                        const parts = parse(option.label, matches)

                        return (
                          <li {...props}>
                            <div>
                              {parts.map((part: any, index: any) => (
                                <span
                                  key={index}
                                  style={{
                                    fontWeight: part.highlight ? 700 : 400,
                                  }}
                                >
                                  {part.text}
                                </span>
                              ))}
                            </div>
                          </li>
                        )
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t("Work Schedule") + " *"}
                          label={t("Work Schedule") + " *"}
                        />
                      )}
                    />
                  </FormControl>
                  {errors.work_schedule?.type === "required" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("Work Schedule is required")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    {/* <InputLabel
                      id="holidaySchedule"
                      error={errors.holiday_schedule ? true : false}
                    >
                      {t("Holiday Schedule") + " *"}
                    </InputLabel>
                    <Select
                      error={errors.holiday_schedule ? true : false}
                      required
                      labelId="holidaySchedule"
                      id="holidaySchedule"
                      label={t("Holiday Schedule") + " *"}
                      {...register("holiday_schedule", { required: true })}
                      value={
                        holidayScheduleList.length > 0
                          ? selectedHolidaySchedule
                          : ""
                      }
                      onChange={(event: SelectChangeEvent) => {
                        setSelectedHolidaySchedule(event.target.value as string)
                        errors.holiday_schedule = undefined
                      }}
                      MenuProps={{ sx: { maxHeight: 500 } }}
                    >
                      {holidayScheduleList.length > 0 &&
                        holidayScheduleList.map(
                          (item: { external_code: string; label: string }) => {
                            return (
                              <MenuItem
                                key={item.external_code}
                                value={item.external_code}
                              >
                                {item.label}
                              </MenuItem>
                            )
                          }
                        )}
                    </Select> */}
                    <Autocomplete
                      onChange={(
                        event,
                        newValue: {
                          external_code: string
                          label: string
                        } | null
                      ) => {
                        setSelectedHolidaySchedule(
                          newValue?.external_code || ""
                        )
                      }}
                      options={holidayScheduleList}
                      getOptionLabel={(option) => option.label}
                      value={
                        holidayScheduleList.filter(
                          (item) =>
                            item.external_code === selectedHolidaySchedule
                        )[0]
                      }
                      renderOption={(
                        props,
                        option,
                        { inputValue, selected }
                      ) => {
                        const matches = match(option.label, inputValue, {
                          insideWords: true,
                        })
                        const parts = parse(option.label, matches)

                        return (
                          <li {...props}>
                            <div>
                              {parts.map((part: any, index: any) => (
                                <span
                                  key={index}
                                  style={{
                                    fontWeight: part.highlight ? 700 : 400,
                                  }}
                                >
                                  {part.text}
                                </span>
                              ))}
                            </div>
                          </li>
                        )
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t("Holiday Schedule") + " *"}
                          label={t("Holiday Schedule") + " *"}
                        />
                      )}
                    />
                  </FormControl>
                  {errors.holiday_schedule?.type === "required" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("Holiday Schedule is required")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="workGroup"
                      error={errors.work_group ? true : false}
                    >
                      {t("Work Group") + " *"}
                    </InputLabel>
                    <Select
                      error={errors.work_group ? true : false}
                      required
                      labelId="workGroup"
                      id="workGroup"
                      label={t("Work Group") + " *"}
                      {...register("work_group", { required: true })}
                      value={workGroupList.length > 0 ? selectedWorkGroup : ""}
                      onChange={(event: SelectChangeEvent) => {
                        setSelectedWorkGroup(event.target.value as string)
                        errors.work_group = undefined
                      }}
                      MenuProps={{ sx: { maxHeight: 500 } }}
                    >
                      {workGroupList.length > 0 &&
                        workGroupList.map(
                          (item: { external_code: string; label: string }) => {
                            return (
                              <MenuItem
                                key={item.external_code}
                                value={item.external_code}
                              >
                                {item.label}
                              </MenuItem>
                            )
                          }
                        )}
                    </Select>
                  </FormControl>
                  {errors.work_group?.type === "required" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("Work Group is required")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    {/* <InputLabel
                      id="payGroup"
                      error={errors.pay_group ? true : false}
                    >
                      {t("Pay Group") + " *"}
                    </InputLabel>
                    <Select
                      error={errors.pay_group ? true : false}
                      required
                      labelId="payGroup"
                      id="payGroup"
                      label={t("Pay Group") + " *"}
                      {...register("pay_group", { required: true })}
                      value={payGroupList.length > 0 ? selectedPayGroup : ""}
                      onChange={(event: SelectChangeEvent) => {
                        setSelectedPayGroup(event.target.value as string)
                        errors.pay_group = undefined
                      }}
                      MenuProps={{ sx: { maxHeight: 500 } }}
                    >
                      {payGroupList.length > 0 &&
                        payGroupList.map(
                          (item: { pay_group_id: string; label: string }) => {
                            return (
                              <MenuItem
                                key={item.pay_group_id}
                                value={item.pay_group_id}
                              >
                                {item.label}
                              </MenuItem>
                            )
                          }
                        )}
                    </Select> */}
                    <Autocomplete
                      onChange={(
                        event,
                        newValue: {
                          pay_group_id: string
                          label: string
                        } | null
                      ) => {
                        setSelectedPayGroup(newValue?.pay_group_id || "")
                      }}
                      options={payGroupList}
                      getOptionLabel={(option) => option.label}
                      value={
                        payGroupList.filter(
                          (item) => item.pay_group_id === selectedPayGroup
                        )[0]
                      }
                      renderOption={(
                        props,
                        option,
                        { inputValue, selected }
                      ) => {
                        const matches = match(option.label, inputValue, {
                          insideWords: true,
                        })
                        const parts = parse(option.label, matches)

                        return (
                          <li {...props}>
                            <div>
                              {parts.map((part: any, index: any) => (
                                <span
                                  key={index}
                                  style={{
                                    fontWeight: part.highlight ? 700 : 400,
                                  }}
                                >
                                  {part.text}
                                </span>
                              ))}
                            </div>
                          </li>
                        )
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t("Pay Group") + " *"}
                          label={t("Pay Group") + " *"}
                        />
                      )}
                    />
                  </FormControl>
                  {errors.pay_group?.type === "required" && (
                    <Typography
                      variant="inherit"
                      color="error"
                      align="left"
                      pt={1}
                      ml={1}
                      fontSize={13}
                    >
                      {t("Pay Group is required")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider
                    dateAdapter={newAdapter}
                    adapterLocale="th"
                  >
                    <MobileDatePicker
                      sx={{ width: "100%" }}
                      label={t("Contract Start Date")}
                      format="DD/MM/YYYY"
                      closeOnSelect
                      {...register("contract_start_date")}
                      localeText={{
                        okButtonLabel: `${t("ok")}`,
                        cancelButtonLabel: `${t("Cancel")}`,
                        clearButtonLabel: `${t("Clear")}`,
                      }}
                      value={selectedContractStartDate}
                      dayOfWeekFormatter={(_day, weekday) =>
                        `${weekday.format("dd")}.`
                      }
                      onChange={(newValue) => {
                        try {
                          if (newValue !== null) {
                            setSelectedContractStartDate(dayjs(newValue))
                          } else {
                            setSelectedContractStartDate(null)
                          }
                        } catch (error) {}
                      }}
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "cancel", "accept"],
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider
                    dateAdapter={newAdapter}
                    adapterLocale="th"
                  >
                    <MobileDatePicker
                      sx={{ width: "100%" }}
                      label={t("Contract End Date")}
                      format="DD/MM/YYYY"
                      {...register("contract_end_date")}
                      closeOnSelect
                      localeText={{
                        okButtonLabel: `${t("ok")}`,
                        cancelButtonLabel: `${t("Cancel")}`,
                        clearButtonLabel: `${t("Clear")}`,
                      }}
                      value={selectedHireDate}
                      dayOfWeekFormatter={(_day, weekday) =>
                        `${weekday.format("dd")}.`
                      }
                      onChange={(newValue) => {
                        try {
                          if (newValue !== null) {
                            // setSelectedContractEndDate(dayjs(newValue));
                            setSelectedHireDate(dayjs(newValue))
                          } else {
                            setSelectedContractEndDate(null)
                          }
                        } catch (error) {}
                      }}
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "cancel", "accept"],
                        },
                      }}
                      minDate={
                        selectedContractStartDate
                          ? selectedContractStartDate
                          : dayjs()
                      }
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="Bank" error={errors.bank ? true : false}>
                      {t("ธนาคาร") + " *"}
                    </InputLabel>
                    <Select
                      // error={errors.bank ? true : false}
                      labelId="Bank"
                      required
                      id="Bank"
                      label={t("Bank") + " *"}
                      // {...register('bank', { required: true })}
                      {...register("bank")}
                      value={bankList.length > 0 ? selectedBank : ""}
                      onChange={(event: SelectChangeEvent) => {
                        setSelectedBank(event.target.value as string)
                        setBankAccountNumber(
                          event.target.value.length <= 0
                            ? ""
                            : bankAccountNumber
                        )
                        // errors.bank = undefined;
                      }}
                      MenuProps={{ sx: { maxHeight: 500 } }}
                    >
                      <MenuItem value="">{t("Not specified")}</MenuItem>
                      {bankList.length > 0 &&
                        bankList.map(
                          (item: { bank_code: string; bank_name: string }) => {
                            return (
                              <MenuItem
                                key={item.bank_code}
                                value={item.bank_code}
                              >
                                {item.bank_name}
                              </MenuItem>
                            )
                          }
                        )}
                      {errors.bank?.type === "required" && (
                        <Typography
                          variant="inherit"
                          color="error"
                          align="left"
                          pt={1}
                          ml={1}
                          fontSize={13}
                        >
                          {t("text_err_required_bank")}
                        </Typography>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="bank_account_number"
                    label={t("Bank account number") + " *"}
                    placeholder={t("Bank account number")!}
                    disabled={selectedBank.length <= 0 ? true : false}
                    {...register("bank_account_number", {
                      required: selectedBank.length > 0 ? true : false,
                    })}
                    // {...register('bank_account_number', { required: true, maxLength: 10, minLength: 10 })}
                    // {...register('bank_account_number')}
                    InputProps={{
                      type: "number",
                      sx: {
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      },
                    }}
                    //comment
                    // inputProps={{ maxLength: 10, minLength: 10 }}
                    value={bankAccountNumber}
                    onChange={(event) => {
                      setBankAccountNumber(event.target.value)
                      // errors.bank_account_number = undefined;
                    }}
                    // error={bankAccountNumber.length > 10 || bankAccountNumber.length < 10 ? true : false}
                    // error={errors.bank_account_number ? true : false}
                  />
                  {/* {errors.bank_account_number?.type === "required" &&
                                        <Typography variant="inherit" color="error" align="left" pt={1} ml={1} fontSize={13}>
                                            {t('text_err_required_bank_number')}
                                        </Typography>
                                    }  */}
                  {selectedBank.length > 0 &&
                    (bankAccountNumber.length > 10 ||
                      bankAccountNumber.length < 10) && (
                      <Typography
                        variant="inherit"
                        color="error"
                        align="left"
                        pt={1}
                        ml={1}
                        fontSize={13}
                      >
                        {t("No. position must be at least 10 characters")}
                      </Typography>
                    )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="amount_101"
                    label={t("101_Amount")}
                    placeholder={t("101_Amount")!}
                    value={Amount101}
                    InputProps={{
                      type: "number",
                      sx: {
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      },
                    }}
                    onChange={(event) => {
                      setAmount101(event.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="amount_102"
                    label={t("102_Amount")}
                    placeholder={t("102_Amount")!}
                    value={Amount102}
                    InputProps={{
                      type: "number",
                      sx: {
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      },
                    }}
                    onChange={(event) => {
                      setAmount102(event.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="amount_103"
                    label={t("103_Amount")}
                    placeholder={t("103_Amount")!}
                    value={Amount103_1}
                    InputProps={{
                      type: "number",
                      sx: {
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      },
                    }}
                    onChange={(event) => {
                      setAmount103_1(event.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="amount_125"
                    label={t("125_Amount")}
                    placeholder={t("125_Amount")!}
                    value={Amount125}
                    InputProps={{
                      type: "number",
                      sx: {
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      },
                    }}
                    onChange={(event) => {
                      setAmount125(event.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="amount_128"
                    label={t("128_Amount")}
                    placeholder={t("128_Amount")!}
                    value={Amount128}
                    InputProps={{
                      type: "number",
                      sx: {
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      },
                    }}
                    onChange={(event) => {
                      setAmount128(event.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="amount_133"
                    label={t("133_Amount")}
                    placeholder={t("133_Amount")!}
                    value={Amount133}
                    InputProps={{
                      type: "number",
                      sx: {
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      },
                    }}
                    onChange={(event) => {
                      setAmount133(event.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="amount_163"
                    label={t("163_Amount")}
                    placeholder={t("163_Amount")!}
                    value={Amount163}
                    InputProps={{
                      type: "number",
                      sx: {
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      },
                    }}
                    onChange={(event) => {
                      setAmount163(event.target.value)
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                px: 3,
                py: 2,
              }}
            >
              <Box sx={{ display: "flex", gap: 1 }}>
                <Button
                  color="error"
                  variant="contained"
                  onClick={() => {
                    onClose()
                    setLoadingECData(true)
                  }}
                  sx={{ px: 3 }}
                >
                  {t("Cancel")}
                </Button>
              </Box>

              <Button
                variant="contained"
                color="primary"
                // onClick={handleClick}
                onClick={handleSubmit(onSubmitted)}
                sx={{ px: 3 }}
                disabled={submittedLoading}
              >
                {t("Confirm")}
                {submittedLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      zIndex: 999,
                    }}
                  />
                )}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </ThemeProvider>
  )
}
